import { faDownload, faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Button } from "react-bootstrap";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { BackupData } from "../../model/CarbonExternalModels";
import BackupDelete from "./BackupDelete";
import { requestClusterBackupDownload } from '../../model/backupRestoreApiCaller';

interface IProps {
    backup: BackupData;
}

export default function BackupActions(props: IProps) {

    const handleDownload = () => {
        requestClusterBackupDownload(props.backup.id, getGlobalStates().keycloak.token);
    }

    return (
        <Dropdown
            className="userfulTableActions"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Dropdown.Toggle bsPrefix="userfulTableActionsToggle" as={Button}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="dropdownMenuSwitch"
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
            >
                {props.backup.status !== "ERROR" && <><Dropdown.Item
                    className="dropdownItem"
                    onClick={(e) => handleDownload()}
                >
                    <FontAwesomeIcon icon={faDownload} />
                    <div className="textInside">Download</div>
                </Dropdown.Item>
                    <hr />
                </>}
                <BackupDelete backup={props.backup} />
            </Dropdown.Menu>
        </Dropdown>
    );
}
