"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePassiveMapper = exports.updateMixerMapperInsets = exports.updateMapperLogicalGrid = exports.updateMapperInsetGeometry = exports.updateMapperHeader = exports.updateMapperDestinations = exports.updateEdgeMapperInsets = exports.stopPassiveMapper = exports.startPassiveMapper = exports.removePassiveMapper = exports.removeMapper = exports.removeHLSStream = exports.removeEdgeMapperInset = exports.getStandalonePassiveMappers = exports.getStandaloneMixerMappers = exports.getStandaloneEdgeMappers = exports.findMappersOfSameApp = exports.findMapper = exports.createStreams = exports.createPlaceholderInsetComp = exports.createMixerMapper = exports.createMJEPStreamComp = exports.createInsetCompFromInsetCompCreator = exports.createHLSStreamComp = exports.createEdgeMapper = exports.addPassiveMapper = exports.addHLSStream = void 0;
var _CdmAudio = require("../../models/cdm/CdmAudio");
var _common = require("../../models/common");
var _MappingGroups = require("../../models/mapping/MappingGroups");
var _TextOverlay = require("../../models/mapping/TextOverlay");
var _store = require("../data/store");
var _uuid = require("uuid");
var _excluded = ["insets"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneMixerMappers = exports.getStandaloneMixerMappers = function getStandaloneMixerMappers() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().mixerMappers);
};
var getStandalonePassiveMappers = exports.getStandalonePassiveMappers = function getStandalonePassiveMappers() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().passiveMappers);
};
var getStandaloneEdgeMappers = exports.getStandaloneEdgeMappers = function getStandaloneEdgeMappers() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().edgeMappers);
};
var removeMapper = exports.removeMapper = function removeMapper(id) {
  var mixerMappers = getStandaloneMixerMappers();
  var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
    return item.id.value === id.value;
  });
  if (foundMixerMapperIndex >= 0) {
    var result = mixerMappers.splice(foundMixerMapperIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      mixerMappers: mixerMappers
    });
    return result;
  }
  var edgeMappers = getStandaloneEdgeMappers();
  var foundEdgeMapperIndex = edgeMappers.findIndex(function (item) {
    return item.id.value === id.value;
  });
  if (foundEdgeMapperIndex >= 0) {
    var _result = edgeMappers.splice(foundEdgeMapperIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      edgeMappers: edgeMappers
    });
    return _result;
  }
  return null;
};
var createMixerMapper = exports.createMixerMapper = function createMixerMapper(creator, passiveMapper, addMJEPStream) {
  var mixerMappers = getStandaloneMixerMappers();
  var data = _objectSpread(_objectSpread({}, creator), {}, {
    gpuID: creator.deploymentHints.preferredGPU,
    groups: [],
    textOverlay: _objectSpread({}, _TextOverlay.TEXTOVERLAY_DEFAULT),
    createdTime: 0,
    updatedTime: 0,
    sceneID: creator.id,
    insets: [],
    destinations: destinationIDSetToMapperCompSurface(creator.destinations),
    streams: addMJEPStream ? [createMJEPStreamComp(creator)] : createStreams(creator.streams, creator),
    mixerPlayerActivationStatus: 'ACTIVE',
    origin: (0, _MappingGroups.createPassiveMapperOriginData)(passiveMapper)
  });
  mixerMappers.push(data);
  (0, _store.updateStandaloneDataStore)({
    mixerMappers: mixerMappers
  });
  var insetsUpdate = {
    id: creator.id,
    addInsets: creator.insets,
    removeInsets: []
  };
  return updateMixerMapperInsets(insetsUpdate);
};
var createEdgeMapper = exports.createEdgeMapper = function createEdgeMapper(creator, passiveMapper) {
  var edgeMappers = getStandaloneEdgeMappers();
  var data = _objectSpread(_objectSpread({}, creator), {}, {
    groups: [],
    createdTime: 0,
    updatedTime: 0,
    sceneID: creator.id,
    destinations: destinationIDSetToMapperCompSurface(creator.destinations),
    inset: createPlaceholderInsetComp(_common.STRINGID_NOT_SET, _common.STRINGID_NOT_SET, 'source'),
    origin: (0, _MappingGroups.createPassiveMapperOriginData)(passiveMapper)
  });
  edgeMappers.push(data);
  (0, _store.updateStandaloneDataStore)({
    edgeMappers: edgeMappers
  });
  return data;
};
var updateMapperHeader = exports.updateMapperHeader = function updateMapperHeader(updateData) {
  var mixerMappers = getStandaloneMixerMappers();
  var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
    return item.id.value === updateData.id.value;
  });
  if (foundMixerMapperIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
      name: updateData.name,
      description: updateData.description
    });
    mixerMappers.splice(foundMixerMapperIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      mixerMappers: mixerMappers
    });
    return update;
  }
  var edgeMappers = getStandaloneEdgeMappers();
  var foundEdgeMapperIndex = edgeMappers.findIndex(function (item) {
    return item.id.value === updateData.id.value;
  });
  if (foundEdgeMapperIndex >= 0) {
    var _update = _objectSpread(_objectSpread({}, edgeMappers[foundEdgeMapperIndex]), {}, {
      name: updateData.name,
      description: updateData.description
    });
    edgeMappers.splice(foundEdgeMapperIndex, 1, _update);
    (0, _store.updateStandaloneDataStore)({
      edgeMappers: edgeMappers
    });
    return _update;
  }
  return null;
};
var addHLSStream = exports.addHLSStream = function addHLSStream(request) {
  var mixerMappers = getStandaloneMixerMappers();
  var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
    return item.id.value === request.id.value;
  });
  if (foundMixerMapperIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
      streams: [createHLSStreamComp(mixerMappers[foundMixerMapperIndex])]
    });
    mixerMappers.splice(foundMixerMapperIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      mixerMappers: mixerMappers
    });
    return update;
  }
};
var removeHLSStream = exports.removeHLSStream = function removeHLSStream(request) {
  var mixerMappers = getStandaloneMixerMappers();
  var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
    return item.id.value === request.value;
  });
  if (foundMixerMapperIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
      streams: mixerMappers[foundMixerMapperIndex].streams.filter(function (s) {
        return s.streamData.type !== 'HLS';
      })
    });
    mixerMappers.splice(foundMixerMapperIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      mixerMappers: mixerMappers
    });
    return update;
  }
};
var updateMapperLogicalGrid = exports.updateMapperLogicalGrid = function updateMapperLogicalGrid(updateData) {
  var mixerMappers = getStandaloneMixerMappers();
  var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
    return item.id.value === updateData.id.value;
  });
  if (foundMixerMapperIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
      logicalGridData: updateData.gridData
    });
    mixerMappers.splice(foundMixerMapperIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      mixerMappers: mixerMappers
    });
    return update;
  }
  return null;
};
var updateMapperInsetGeometry = exports.updateMapperInsetGeometry = function updateMapperInsetGeometry(updateData) {
  var mixerMappers = getStandaloneMixerMappers();
  var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
    return item.id.value === updateData.mapperID.value;
  });
  if (foundMixerMapperIndex >= 0) {
    var insets = _toConsumableArray(mixerMappers[foundMixerMapperIndex].insets);
    var foundInsetIndex = insets.findIndex(function (item) {
      return item.id.value === updateData.id.value;
    });
    if (foundInsetIndex >= 0) {
      insets.splice(foundInsetIndex, 1, _objectSpread(_objectSpread({}, insets[foundInsetIndex]), {}, {
        videoData: [_objectSpread(_objectSpread({}, insets[foundInsetIndex].videoData[0]), {}, {
          position: updateData.positionInfo
        })]
      }));
      var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
        insets: insets
      });
      mixerMappers.splice(foundMixerMapperIndex, 1, update);
      (0, _store.updateStandaloneDataStore)({
        mixerMappers: mixerMappers
      });
      return update;
    }
  }
  return null;
};
var createInsetCompFromInsetCompCreator = exports.createInsetCompFromInsetCompCreator = function createInsetCompFromInsetCompCreator(creator, mapper) {
  return createPlaceholderInsetComp(creator.id, creator["@type"] === 'm_playlist' ? creator.playListDef : creator.sourceDef, creator["@type"] === 'm_playlist' ? 'playlist' : 'source', creator.name, creator.description, creator.videoData, creator.audioData, mapper);
};
var updateMixerMapperInsets = exports.updateMixerMapperInsets = function updateMixerMapperInsets(updateData) {
  var mixerMappers = getStandaloneMixerMappers();
  var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
    return item.id.value === updateData.id.value;
  });
  if (foundMixerMapperIndex >= 0) {
    var updatedInsets = mixerMappers[foundMixerMapperIndex].insets.filter(function (item) {
      return updateData.removeInsets.findIndex(function (toRemove) {
        return toRemove.value === item.id.value;
      }) < 0;
    });
    updatedInsets.push.apply(updatedInsets, _toConsumableArray(updateData.addInsets.map(function (item) {
      return createInsetCompFromInsetCompCreator(item, mixerMappers[foundMixerMapperIndex]);
    })));
    var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
      insets: updatedInsets
    });
    mixerMappers.splice(foundMixerMapperIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      mixerMappers: mixerMappers
    });
    return update;
  }
  return null;
};
var updateEdgeMapperInsets = exports.updateEdgeMapperInsets = function updateEdgeMapperInsets(updateData) {
  var edgeMappers = getStandaloneEdgeMappers();
  var found = edgeMappers.findIndex(function (item) {
    return item.id.value === updateData.id.value;
  });
  if (found >= 0) {
    var updatedInset = createInsetCompFromInsetCompCreator(updateData.inset);
    var update = _objectSpread(_objectSpread({}, edgeMappers[found]), {}, {
      inset: updatedInset
    });
    edgeMappers.splice(found, 1, update);
    (0, _store.updateStandaloneDataStore)({
      edgeMappers: edgeMappers
    });
    return update;
  }
  return null;
};
var removeEdgeMapperInset = exports.removeEdgeMapperInset = function removeEdgeMapperInset(mapperID) {
  var edgeMappers = getStandaloneEdgeMappers();
  var found = edgeMappers.findIndex(function (item) {
    return item.id.value === mapperID.value;
  });
  if (found >= 0) {
    var updatedInset = createPlaceholderInsetComp(_common.STRINGID_NOT_SET, _common.STRINGID_NOT_SET, 'source');
    var update = _objectSpread(_objectSpread({}, edgeMappers[found]), {}, {
      inset: updatedInset
    });
    edgeMappers.splice(found, 1, update);
    (0, _store.updateStandaloneDataStore)({
      edgeMappers: edgeMappers
    });
    return update;
  }
  return null;
};
var updateMapperDestinations = exports.updateMapperDestinations = function updateMapperDestinations(updateData) {
  var mixerMappers = getStandaloneMixerMappers();
  var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
    return item.id.value === updateData.id.value;
  });
  if (foundMixerMapperIndex >= 0) {
    var updateDests = mixerMappers[foundMixerMapperIndex].destinations.filter(function (item) {
      return destinationIDSetToMapperCompSurface(updateData.removeDestinations).findIndex(function (toRemove) {
        return toRemove.destinationID === item.destinationID;
      }) < 0;
    });
    updateDests.push.apply(updateDests, _toConsumableArray(destinationIDSetToMapperCompSurface(updateData.addDestinations)));
    var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
      destinations: updateDests
    });
    mixerMappers.splice(foundMixerMapperIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      mixerMappers: mixerMappers
    });
    return update;
  }
  var edgeMappers = getStandaloneEdgeMappers();
  var foundEdgeMapperIndex = edgeMappers.findIndex(function (item) {
    return item.id.value === updateData.id.value;
  });
  if (foundEdgeMapperIndex >= 0) {
    var _updateDests = edgeMappers[foundEdgeMapperIndex].destinations.filter(function (item) {
      return destinationIDSetToMapperCompSurface(updateData.removeDestinations).findIndex(function (toRemove) {
        return toRemove.destinationID === item.destinationID;
      }) < 0;
    });
    _updateDests.push.apply(_updateDests, _toConsumableArray(destinationIDSetToMapperCompSurface(updateData.addDestinations)));
    var _update2 = _objectSpread(_objectSpread({}, edgeMappers[foundEdgeMapperIndex]), {}, {
      destinations: _updateDests
    });
    edgeMappers.splice(foundEdgeMapperIndex, 1, _update2);
    (0, _store.updateStandaloneDataStore)({
      edgeMappers: edgeMappers
    });
    return _update2;
  }
  return null;
};
var addPassiveMapper = exports.addPassiveMapper = function addPassiveMapper(item) {
  var passiveMappers = getStandalonePassiveMappers();
  passiveMappers.push(item);
  (0, _store.updateStandaloneDataStore)({
    passiveMappers: passiveMappers
  });
  return item;
};
var updatePassiveMapper = exports.updatePassiveMapper = function updatePassiveMapper(item) {
  var passiveMappers = getStandalonePassiveMappers();
  var foundIndex = passiveMappers.findIndex(function (p) {
    return p.id.value === item.id.value;
  });
  if (foundIndex >= 0) {
    passiveMappers.splice(foundIndex, 1, item);
    (0, _store.updateStandaloneDataStore)({
      passiveMappers: passiveMappers
    });
  }
  return item;
};
var removePassiveMapper = exports.removePassiveMapper = function removePassiveMapper(id) {
  var passiveMappers = getStandalonePassiveMappers();
  var found = passiveMappers.findIndex(function (item) {
    return item.id.value === id.value;
  });
  if (found >= 0) {
    var result = passiveMappers.splice(found, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      passiveMappers: passiveMappers
    });
    return result;
  }
  return null;
};
var findMapper = exports.findMapper = function findMapper(id) {
  return getStandaloneMixerMappers().find(function (m) {
    return m.id.value === id.value;
  }) || getStandaloneEdgeMappers().find(function (m) {
    return m.id.value === id.value;
  });
};
var findMappersOfSameApp = exports.findMappersOfSameApp = function findMappersOfSameApp(mapper) {
  return [].concat(_toConsumableArray(getStandaloneMixerMappers().filter(function (m) {
    return m.appInstanceID.appID.value === mapper.appInstanceID.appID.value && m.id.value !== mapper.id.value;
  })), _toConsumableArray(getStandaloneEdgeMappers().filter(function (m) {
    return m.appInstanceID.appID.value === mapper.appInstanceID.appID.value && m.id.value !== mapper.id.value;
  })));
};
var startPassiveMapper = exports.startPassiveMapper = function startPassiveMapper(id) {
  var passiveMappers = getStandalonePassiveMappers();
  var found = passiveMappers.find(function (item) {
    return item.id.value === id.value;
  });
  if (found) {
    if (found.createMapperType === 'EDGE') {
      var insets = found.insets,
        createData = _objectWithoutProperties(found, _excluded);
      var result = createEdgeMapper(createData, found);
      if (insets.length > 0) {
        result = updateEdgeMapperInsets({
          id: found.id,
          inset: insets[0]
        });
      }
      return result;
    } else {
      return createMixerMapper(found, found);
    }
  }
  return null;
};
var stopPassiveMapper = exports.stopPassiveMapper = function stopPassiveMapper(id) {
  return removeMapper(id);
};
var createPlaceholderInsetComp = exports.createPlaceholderInsetComp = function createPlaceholderInsetComp(insetID, itemID, type, insetName, insetDescription, videoData, audioData, mapper) {
  var foundSource = (0, _store.getStandaloneDataStore)().sources.find(function (s) {
    return s.id.value === itemID.value;
  });
  var foundPlaylist = (0, _store.getStandaloneDataStore)().playlists.find(function (p) {
    return p.id.value === itemID.value;
  });
  return {
    id: insetID,
    name: insetName ? insetName : "",
    description: insetDescription ? insetDescription : "",
    sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.sceneID) || _common.STRINGID_NOT_SET,
    playList: {
      id: (foundPlaylist === null || foundPlaylist === void 0 ? void 0 : foundPlaylist.id) || _common.STRINGID_NOT_SET,
      name: (foundPlaylist === null || foundPlaylist === void 0 ? void 0 : foundPlaylist.name) || "",
      description: (foundPlaylist === null || foundPlaylist === void 0 ? void 0 : foundPlaylist.description) || "",
      sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.sceneID) || _common.STRINGID_NOT_SET,
      createdFrom: type === 'playlist' ? _objectSpread({}, itemID) : _common.STRINGID_NOT_SET,
      behaviour: {
        playTimes: {
          times: 0
        },
        startBehaviour: 'AUTO_START'
      },
      items: type === 'playlist' && foundPlaylist ? foundPlaylist.items.map(function (i) {
        var _i$sourceDef, _i$sourceDef2, _i$sourceDef3, _i$sourceDef4, _i$sourceDef5, _i$sourceDef6, _i$sourceDef7;
        return {
          id: i.id,
          name: ((_i$sourceDef = i.sourceDef) === null || _i$sourceDef === void 0 ? void 0 : _i$sourceDef.name) || "",
          description: ((_i$sourceDef2 = i.sourceDef) === null || _i$sourceDef2 === void 0 ? void 0 : _i$sourceDef2.description) || "",
          createdFrom: ((_i$sourceDef3 = i.sourceDef) === null || _i$sourceDef3 === void 0 ? void 0 : _i$sourceDef3.id) || _common.STRINGID_NOT_SET,
          behaviour: {
            activationDuration: {
              millis: 0
            },
            preStartDuration: {
              millis: 0
            },
            playDuration: {
              millis: 0
            },
            repeatConfig: {
              times: {
                times: -1
              },
              duration: {
                millis: 0
              }
            },
            persistenceType: 'KEEP_RUNNING'
          },
          source: {
            id: ((_i$sourceDef4 = i.sourceDef) === null || _i$sourceDef4 === void 0 ? void 0 : _i$sourceDef4.id) || _common.STRINGID_NOT_SET,
            name: ((_i$sourceDef5 = i.sourceDef) === null || _i$sourceDef5 === void 0 ? void 0 : _i$sourceDef5.name) || "",
            description: ((_i$sourceDef6 = i.sourceDef) === null || _i$sourceDef6 === void 0 ? void 0 : _i$sourceDef6.description) || "",
            sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.sceneID) || _common.STRINGID_NOT_SET,
            createdFrom: ((_i$sourceDef7 = i.sourceDef) === null || _i$sourceDef7 === void 0 ? void 0 : _i$sourceDef7.id) || _common.STRINGID_NOT_SET,
            type: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.type) || {
              value: _common.NOT_SET_VALUE,
              name: _common.NOT_SET_VALUE
            },
            params: [],
            displayResolution: {
              width: 1920,
              height: 1080
            },
            outputType: 'VIDEO_AND_AUDIO',
            controlMode: 'NONE',
            scaling: 'INSIDE',
            sharingMode: 'ALLOW_SHARING_SOURCE',
            seatID: _common.STRINGID_NOT_SET
          }
        };
      }) : [{
        id: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.id) || _common.STRINGID_NOT_SET,
        name: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.name) || "",
        description: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.description) || "",
        createdFrom: _common.STRINGID_NOT_SET,
        behaviour: {
          activationDuration: {
            millis: 0
          },
          preStartDuration: {
            millis: 0
          },
          playDuration: {
            millis: 0
          },
          repeatConfig: {
            times: {
              times: -1
            },
            duration: {
              millis: 0
            }
          },
          persistenceType: 'KEEP_RUNNING'
        },
        source: {
          id: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.id) || _common.STRINGID_NOT_SET,
          name: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.name) || "",
          description: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.description) || "",
          sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.sceneID) || _common.STRINGID_NOT_SET,
          createdFrom: type === 'source' ? _objectSpread({}, itemID) : _common.STRINGID_NOT_SET,
          type: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.type) || {
            value: _common.NOT_SET_VALUE,
            name: _common.NOT_SET_VALUE
          },
          params: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.params) || [],
          displayResolution: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.displayResolution) || {
            width: 1920,
            height: 1080
          },
          outputType: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.outputType) || 'VIDEO_AND_AUDIO',
          controlMode: 'NONE',
          scaling: (foundSource === null || foundSource === void 0 ? void 0 : foundSource.scaling) || 'INSIDE',
          seatID: _common.STRINGID_NOT_SET
        }
      }]
    },
    audioData: createAudioData(audioData, mapper),
    videoData: createVideoData(videoData, mapper)
  };
};
var createVideoData = function createVideoData(videoData, mapper) {
  return [{
    id: _common.STRINGID_NOT_SET,
    mapperID: (mapper === null || mapper === void 0 ? void 0 : mapper.id) || _common.STRINGID_NOT_SET,
    sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.id) || _common.STRINGID_NOT_SET,
    videoMixerID: (mapper === null || mapper === void 0 ? void 0 : mapper.id) || _common.STRINGID_NOT_SET,
    position: (videoData === null || videoData === void 0 ? void 0 : videoData.position) || _common.POSITION_FULL,
    visibility: (videoData === null || videoData === void 0 ? void 0 : videoData.visibility) || "VISIBLE",
    movable: "ACTIVE",
    showSourceName: "INVISIBLE",
    textOverlay: {
      text: "",
      visibility: "INVISIBLE",
      config: {
        colour: "ORANGE",
        font: {
          fontName: "Sans"
        },
        style: {
          styleName: "Bold"
        },
        textSize: 32,
        shadow: false,
        outline: true,
        outlineColour: "BLACK",
        xpos: 0.05,
        ypos: 0.05
      }
    }
  }];
};
var createAudioData = function createAudioData(audioData, mapper) {
  return [{
    id: _common.STRINGID_NOT_SET,
    mapperID: (mapper === null || mapper === void 0 ? void 0 : mapper.id) || _common.STRINGID_NOT_SET,
    sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.id) || _common.STRINGID_NOT_SET,
    audioMixerID: (mapper === null || mapper === void 0 ? void 0 : mapper.id) || _common.STRINGID_NOT_SET,
    visibility: (audioData === null || audioData === void 0 ? void 0 : audioData.visibility) || "VISIBLE",
    audioSettings: (audioData === null || audioData === void 0 ? void 0 : audioData.audioSettings) || _CdmAudio.GWTAUDIO_MUTED
  }];
};
var destinationIDSetToMapperCompSurface = function destinationIDSetToMapperCompSurface(destinations) {
  return [].concat(_toConsumableArray(destinations.groups.map(function (id) {
    return {
      id: _common.STRINGID_NOT_SET,
      destinationID: id.value,
      surfaceType: "SINGLE"
    };
  })), _toConsumableArray(destinations.videoWalls.map(function (id) {
    return {
      id: _common.STRINGID_NOT_SET,
      destinationID: id.value,
      surfaceType: "SINGLE"
    };
  })), _toConsumableArray(destinations.physicalVideo.map(function (id) {
    return {
      id: _common.STRINGID_NOT_SET,
      destinationID: id.value,
      surfaceType: "SINGLE"
    };
  })), _toConsumableArray(destinations.physicalAudio.map(function (id) {
    return {
      id: _common.STRINGID_NOT_SET,
      destinationID: id.value,
      surfaceType: "SINGLE"
    };
  })));
};
var createStreams = exports.createStreams = function createStreams(creators, mapper) {
  return creators.map(function (c) {
    return _objectSpread(_objectSpread({}, c), {}, {
      sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.id) || _common.STRINGID_NOT_SET,
      deploymentInfo: {
        audioMixerID: {
          value: (0, _uuid.v4)()
        },
        videoMixerID: {
          value: (0, _uuid.v4)()
        }
      },
      streamData: {
        id: c.id,
        type: c.sinkVideoProtocol["@type"] === "mjpeg" ? "MJPEG" : "HLS",
        url: c.id.value
      }
    });
  });
};
var createHLSStreamComp = exports.createHLSStreamComp = function createHLSStreamComp(mapper) {
  return {
    id: {
      value: "5e32b370-c2da-45d1-ade5-4d4ac2aed126-F9E36208-1CF0-4296-B991-CE417F81B195"
    },
    name: "HLS-a",
    description: "",
    sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.sceneID) || _common.STRINGID_NOT_SET,
    deploymentInfo: {
      audioMixerID: {
        value: "77884B41-3A6D-4254-B45B-CDA030308C12"
      },
      videoMixerID: {
        value: "9E623850-A966-4C13-B983-031A7D448442"
      }
    },
    resolution: {
      width: 1920,
      height: 1080
    },
    audioSettings: {
      audioVolume: {
        level: 100,
        muted: false
      },
      audioOffset: {
        millis: 0
      }
    },
    positionInfo: {
      center: {
        x: 50,
        y: 50
      },
      size: {
        width: 100,
        height: 100
      },
      rotation: {
        radians: 0
      },
      zorder: {
        value: 1
      },
      alpha: {
        value: 1
      }
    },
    streamData: {
      id: {
        value: "5e32b370-c2da-45d1-ade5-4d4ac2aed126-F9E36208-1CF0-4296-B991-CE417F81B195"
      },
      type: "HLS",
      url: "/hls/streams/5e32b370-c2da-45d1-ade5-4d4ac2aed126-F9E36208-1CF0-4296-B991-CE417F81B195/playlist.m3u8"
    }
  };
};
var createMJEPStreamComp = exports.createMJEPStreamComp = function createMJEPStreamComp(mapper) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "LiveUpdates-a91beb72-1a39-41e5-86d3-79f7b5394ae0",
    description: "",
    sceneID: (mapper === null || mapper === void 0 ? void 0 : mapper.id) || _common.STRINGID_NOT_SET,
    deploymentInfo: {
      audioMixerID: {
        value: "7F32A9CF-9259-4A5E-A18A-613F16074DA6"
      },
      videoMixerID: {
        value: "4A7C9D7C-CD1D-4712-9350-690F41D62705"
      }
    },
    resolution: {
      width: 864,
      height: 480
    },
    audioSettings: {
      audioVolume: {
        level: 0,
        muted: true
      },
      audioOffset: {
        millis: 0
      }
    },
    positionInfo: {
      center: {
        x: 50,
        y: 50
      },
      size: {
        width: 100,
        height: 100
      },
      rotation: {
        radians: 0
      },
      zorder: {
        value: 1
      },
      alpha: {
        value: 1
      }
    },
    streamData: {
      id: {
        value: "8689D671-0D33-49CF-A363-EFE66F3EF2C5"
      },
      type: "MJPEG",
      url: "53548FFE-42FF-45BE-85D8-1B671BA6B127"
    }
  };
};