"use strict";

var _DestinationsMappingMsgDefinitions = require("../../../message/messageTypeDefinitions/mapping/DestinationsMappingMsgDefinitions");
var _MappingMsgDefinations = require("../../../message/messageTypeDefinitions/mapping/MappingMsgDefinations");
var _PassiveCollectionMsgDefinitions = require("../../../message/messageTypeDefinitions/mapping/PassiveCollectionMsgDefinitions");
var _scheduleMsgs = require("../../../message/messageTypeDefinitions/schedule/scheduleMsgs");
var _msgTypes = require("../../../models/layouts/messages/msgTypes");
var _MappingGroups = require("../../../models/mapping/MappingGroups");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _ucontrol = require("../../functions/ucontrol");
// Custom Preset
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_PassiveCollectionMsgDefinitions.REQUEST_LIST_PASSIVE_COLLECTIONS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_PassiveCollectionMsgDefinitions.RESPONSE_LIST_PASSIVE_COLLECTIONS, (0, _ucontrol.getStandalonePassiveCollections)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_PassiveCollectionMsgDefinitions.REQUEST_CREATE_PASSIVE_COLLECTION, function (payload) {
  var result = (0, _ucontrol.createPassiveCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_PassiveCollectionMsgDefinitions.RESPONSE_CREATE_PASSIVE_COLLECTION, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_PassiveCollectionMsgDefinitions.REQUEST_UPDATE_PASSIVE_COLLECTION, function (payload) {
  var result = (0, _ucontrol.updatePassiveCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_PassiveCollectionMsgDefinitions.RESPONSE_UPDATE_PASSIVE_COLLECTION, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_PassiveCollectionMsgDefinitions.REQUEST_REMOVE_PASSIVE_COLLECTION, function (payload) {
  var result = (0, _ucontrol.removePassiveCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_REMOVE_PASSIVE_MAPPER, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_PassiveCollectionMsgDefinitions.REQUEST_START_PASSIVE_COLLECTION, function (payload) {
  var result = (0, _ucontrol.startPassiveCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_PassiveCollectionMsgDefinitions.RESPONSE_START_PASSIVE_COLLECTION, payload, null, 2000 * result.length);
  result.forEach(function (r) {
    if ((0, _MappingGroups.isEdgeMapper)(r)) {
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_CREATE_EDGE_MAPPER, r, null, 2000 * result.length);
    } else {
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_CREATE_MAPPER, r, null, 2000 * result.length);
    }
  });
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_PassiveCollectionMsgDefinitions.REQUEST_STOP_PASSIVE_COLLECTION, function (payload) {
  var result = (0, _ucontrol.stopPassiveCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_PassiveCollectionMsgDefinitions.RESPONSE_STOP_PASSIVE_COLLECTION, payload, null, 1000 * result.length);
  result.forEach(function (r) {
    if ((0, _MappingGroups.isEdgeMapper)(r)) {
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_REMOVE_EDGE_MAPPER, r.id, null, 1000 * result.length);
    } else {
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_REMOVE_MAPPER, r.id, null, 1000 * result.length);
    }
  });
});

// Destination Presets
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS, (0, _ucontrol.getStandaloneDestinationMappings)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_CREATE_MAPPER_DESTINATION_MAPPING, function (payload) {
  var result = (0, _ucontrol.createDestinationMapping)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.NOTIFY_CREATE_MAPPER_DESTINATION_MAPPING, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_UPDATE_MAPPER_DESTINATION_MAPPING, function (payload) {
  var result = (0, _ucontrol.updateDestinationMapping)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPING, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_REMOVE_MAPPER_DESTINATION_MAPPING, function (payload) {
  var result = (0, _ucontrol.removeDestinationMapping)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPING, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS_COLLECTIONS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS_COLLECTIONS, (0, _ucontrol.getStandaloneDestinationMappingCollections)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, function (payload) {
  var result = (0, _ucontrol.createDestinationMappingCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.NOTIFY_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, function (payload) {
  var result = (0, _ucontrol.updateDestinationMappingCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, function (payload) {
  var result = (0, _ucontrol.removeDestinationMappingCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_DestinationsMappingMsgDefinitions.REQUEST_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION, function (payload) {
  var result = (0, _ucontrol.startDestinationCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_DestinationsMappingMsgDefinitions.NOTIFY_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION, payload, null, 1000 * result.length);
});

// Destination Presets
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_LIST_MAPPER_LAYOUT_MAPPINGS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_LIST_MAPPING_LAYAOUT_MAPPINGS, (0, _ucontrol.getStandaloneLayoutMappings)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_CREATE_MAPPER_LAYOUT_MAPPING, function (payload) {
  var result = (0, _ucontrol.createLayoutMapping)(payload);
  var schedulable = {
    id: {
      value: result.id.value,
      type: 'LAYOUT'
    },
    name: result.name,
    description: result.description,
    type: "LAYOUT"
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.NOTIFY_CREATE_MAPPER_LAYOUT_MAPPING, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_SCHEDULABLE_CREATED, schedulable);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_MAPPING, function (payload) {
  var result = (0, _ucontrol.updateLayoutMapping)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.NOTIFY_UPDATE_MAPPER_LAYOUT_MAPPING, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_REMOVE_MAPPER_LAYOUT_MAPPING, function (payload) {
  var result = (0, _ucontrol.removeLayoutMapping)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.NOTIFY_REMOVE_MAPPER_LAYOUT_MAPPING, payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_SCHEDULABLE_REMOVED, {
    value: payload,
    type: "LAYOUT"
  });
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_LIST_MAPPER_LAYOUT_MAPPINGS_COLLECTIONS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_LIST_MAPPING_LAYAOUT_MAPPINGS_COLLECTIONS, (0, _ucontrol.getStandaloneLayoutMappingCollections)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_CREATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, function (payload) {
  var result = (0, _ucontrol.createLayoutMappingCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.NOTIFY_CREATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, function (payload) {
  var result = (0, _ucontrol.updateLayoutMappingCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.NOTIFY_UPDATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_REMOVE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, function (payload) {
  var result = (0, _ucontrol.removeLayoutMappingCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.NOTIFY_REMOVE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_APPLY_MAPPING_LAYOUT_MAPPINGS_COLLECTION, function (payload) {
  var result = (0, _ucontrol.startLayoutMappingCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.NOTIFY_APPLY_MAPPING_LAYOUT_MAPPINGS_COLLECTION, payload, null, 1000 * result.length);
});