import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import { ClusterDeviceInfo, IClusterInfo, IConstellationInfo } from "../../../model/CarbonExternalModels";
import { AppSliceActions } from "../../../store/app/appSlice";
import { selectDashboards, useCarbonDispatch, useCarbonSelector } from "../../../store/carbonStore";

import DevicesDropdown from "./devicesDropdown/DevicesDropdown";
import DeviceSystemInfo from "./DeviceSystemInfo";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

import "./DevicePage.scss";
interface IProps {
  foundObject: { cluster: IClusterInfo; constellation: IConstellationInfo; device: ClusterDeviceInfo };
}

type TDevice = "SYSTEM_INFO" | "NETWORKING" | "GPU" | "CPU" | "STORAGE" | "CAPACITY";

export default function DevicePage({ foundObject }: IProps) {
  const [clusterView, setDeviceView] = useState<TDevice>("SYSTEM_INFO");

  const dispatch = useCarbonDispatch();

  const dashboards = useCarbonSelector(selectDashboards);

  const dashboardRef = useRef(null);

  const insertURL = (): string => {
    const dashboard = dashboards[foundObject.cluster.id.value];
    let url;

    if (clusterView === "NETWORKING") {
      url = dashboard.uris["NETWORK"];
    } else if (clusterView === "GPU") {
      url = dashboard.uris["GPU"];
    } else if (clusterView === "CPU") {
      url = dashboard.uris["CPU"];
    } else if (clusterView === "STORAGE") {
      url = dashboard.uris["STORAGE"];
    } else if (clusterView === "CAPACITY") {
      url = dashboard.uris["CAPACITY"];
    }
    return url;
  };

  useEffect(() => {
    const iframe = dashboardRef.current;

    if (iframe) {
      iframe.onload = () => {
        iframe.contentWindow.postMessage(
          {
            Authorization: "Bearer " + getGlobalStates().keycloak.token,
          },
          "https://modjo-grafana.gcm2.userful.dev"
        );
      };
    }
  }, []);

  return (
    <div className="genericWrapper">
      <div className="actionsBar">
        <div className="cluster normalGap">
          <UserfulTooltip title={"Return to "}>
            <button
              className="iconButton"
              onClick={(e) => {
                dispatch(AppSliceActions.setAppView({ type: "CLUSTER", id: foundObject.cluster.id }));
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </UserfulTooltip>
          <DevicesDropdown foundObject={foundObject} />
          <div className="multiStateWrapper">
            <div
              className={`mSbutton ${clusterView === "SYSTEM_INFO" ? "active" : ""}`}
              onClick={(e) => {
                setDeviceView("SYSTEM_INFO");
              }}
            >
              <p>System Info</p>
            </div>
            <div
              className={`mSbutton ${clusterView === "NETWORKING" ? "active" : ""}`}
              onClick={(e) => {
                setDeviceView("NETWORKING");
              }}
            >
              <p>Networking</p>
            </div>
            <div
              className={`mSbutton ${clusterView === "GPU" ? "active" : ""}`}
              onClick={(e) => {
                setDeviceView("GPU");
              }}
            >
              <p>GPU</p>
            </div>
            <div
              className={`mSbutton ${clusterView === "CPU" ? "active" : ""}`}
              onClick={(e) => {
                setDeviceView("CPU");
              }}
            >
              <p>CPU</p>
            </div>
            <div
              className={`mSbutton ${clusterView === "STORAGE" ? "active" : ""}`}
              onClick={(e) => {
                setDeviceView("STORAGE");
              }}
            >
              <p>Storage</p>
            </div>
            <div
              className={`mSbutton ${clusterView === "CAPACITY" ? "active" : ""}`}
              onClick={(e) => {
                setDeviceView("CAPACITY");
              }}
            >
              <p>Capacity</p>
            </div>
          </div>
        </div>
        <div className="cluster normalGap">
          {/* <UserfulTooltip title={"Delete"}>
                        <button className="iconButton">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </UserfulTooltip> */}
        </div>
      </div>
      {clusterView === "SYSTEM_INFO" && (
        <div className="device-body">
          <DeviceSystemInfo foundObject={foundObject} />
        </div>
      )}
      {clusterView !== "SYSTEM_INFO" && <iframe ref={dashboardRef} className="full-iframe-container" src={insertURL()} />}
    </div>
  );
}
