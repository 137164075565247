"use strict";

var _messageModel = require("../../../message/messageModel");
var _UClientsMsgDefinations = require("../../../message/messageTypeDefinitions/displays/UClientsMsgDefinations");
var _novastarMsgDefinitions = require("../../../message/messageTypeDefinitions/integration/novastarMsgDefinitions");
var _megapixelMsgDefinitions = require("../../../message/messageTypeDefinitions/integration/megapixelMsgDefinitions");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _config = require("../../functions/config");
var _proxyMsgDefinitions = require("../../../message/messageTypeDefinitions/config/proxyMsgDefinitions");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(new _messageModel.RequestMessageDefinition(2005, 1), function (payload) {
  var RESPONSE = new _messageModel.RespondMessageDefinition(2005, 0);
  if (payload.data.connectionState === 'ACTIVE') {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(RESPONSE, {
      data: {
        connectionState: "CONNECTING",
        ipAddress: {
          value: "0.0.0.0"
        }
      }
    });
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(RESPONSE, {
      data: {
        connectionState: "ACTIVE",
        ipAddress: {
          value: "10.0.0.5"
        }
      }
    }, undefined, 2000);
  } else {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(RESPONSE, {
      data: {
        connectionState: "INACTIVE",
        ipAddress: {
          value: "0.0.0.0"
        }
      }
    });
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(new _messageModel.RequestMessageDefinition(2005, 0), function (payload) {
  var RESPONSE = new _messageModel.RespondMessageDefinition(2005, 0);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(RESPONSE, {
    data: {
      connectionState: "INACTIVE",
      ipAddress: {
        value: "0.0.0.0"
      }
    }
  });
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_novastarMsgDefinitions.REQUEST_ADD_CONTROLLER, function (payload) {
  (0, _StandaloneMsgHandler.respondFeatureNotAvailable)();
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_megapixelMsgDefinitions.REQUEST_ADD_HELIOS_CONTROLLER, function (payload) {
  (0, _StandaloneMsgHandler.respondFeatureNotAvailable)();
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_megapixelMsgDefinitions.REQUEST_ADD_OMNIS_CONTROLLER, function (payload) {
  (0, _StandaloneMsgHandler.respondFeatureNotAvailable)();
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_EVENT_LOG, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.RESPONSE_EVENT_LOG, [], null, 2000);
});
var REQUEST_HOST_NAME = new _messageModel.RequestMessageDefinition(2003, 0);
var REQUEST_HOST_TIME_DATA = new _messageModel.RequestMessageDefinition(2022, 0);
var REQUEST_NETWORK_SETTING_DATA = new _messageModel.RequestMessageDefinition(2003, 2);
var GET_HOST_NAME = new _messageModel.RespondMessageDefinition(2003, 0);
var GET_HOST_TIME_DATA = new _messageModel.RespondMessageDefinition(2022, 0);
var GET_NETWORK_SETTING_DATA = new _messageModel.RespondMessageDefinition(2003, 1);
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(REQUEST_HOST_TIME_DATA, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(GET_HOST_TIME_DATA, (0, _config.getTimeData)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(REQUEST_NETWORK_SETTING_DATA, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(GET_NETWORK_SETTING_DATA, (0, _config.getNetworkData)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(REQUEST_HOST_NAME, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(GET_HOST_NAME, (0, _config.getHostnameData)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_proxyMsgDefinitions.REQUEST_PROXY_SETTINGS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_proxyMsgDefinitions.RESPOND_PROXY_SETTINGS, (0, _config.getProxyData)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_proxyMsgDefinitions.REQUEST_UPDATE_PROXY_SETTINGS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_proxyMsgDefinitions.RESPOND_UPDATE_PROXY_SETTINGS, (0, _config.setProxyData)(payload));
});