"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLayoutInsets = exports.updateLayoutHeader = exports.updateLayoutGrid = exports.updateLayoutGeometry = exports.updateLayoutCanvasSize = exports.removeLayout = exports.getStandaloneMapperLayout = exports.createLayoutFromLayout = exports.createLayout = exports.applyLayoutToOtherLayout = exports.applyLayoutToOriginalMapper = exports.applyLayoutToMapper = void 0;
var _common = require("../../models/common");
var _MappingGroups = require("../../models/mapping/MappingGroups");
var _store = require("../data/store");
var _mappers = require("./mappers");
var _utils = require("./utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneMapperLayout = exports.getStandaloneMapperLayout = function getStandaloneMapperLayout() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().globalLayouts);
};
var createLayout = exports.createLayout = function createLayout(request) {
  var foundMapper = (0, _mappers.getStandaloneMixerMappers)().find(function (m) {
    return m.id.value === request.createdFrom.value;
  });
  if (!foundMapper) {
    return null;
  }
  var globalLayouts = _toConsumableArray((0, _store.getStandaloneDataStore)().globalLayouts);
  var data = {
    id: request.id,
    name: request.name,
    description: request.description,
    insets: foundMapper.insets,
    mapperCompType: "MIXER",
    createdFrom: request.createdFrom || foundMapper.id,
    createInstant: new Date().getTime() / 1000,
    mapperLayoutType: request.mapperLayoutType,
    canvasSize: foundMapper.canvasSize,
    logicalGridData: foundMapper.logicalGridData,
    streamCompCreators: [],
    insetCompCreators: foundMapper.insets.map(function (i) {
      return insetCompToCreators(i);
    })
  };
  globalLayouts.push(data);
  (0, _store.updateStandaloneDataStore)({
    globalLayouts: globalLayouts
  });
  return data;
};
var insetCompToCreators = function insetCompToCreators(inset) {
  var _inset$playList$items;
  var defaultValue = (0, _MappingGroups.createDefaultMapperInsetCreatorConfig)();
  if ((0, _MappingGroups.isMapperSourceFromPlaylist)(inset)) {
    var _result = {
      id: inset.id,
      name: inset.name,
      description: inset.description,
      playListDef: inset.playList.createdFrom,
      sharingMode: 'USE_SOURCE_DEF_VALUE',
      audioData: inset.audioData.map(function (a) {
        return {
          audioSettings: a.audioSettings,
          visibility: a.visibility
        };
      })[0] || defaultValue.audioData,
      videoData: inset.videoData.map(function (v) {
        return {
          position: v.position,
          visibility: v.visibility,
          movable: v.movable
        };
      })[0] || defaultValue.videoData,
      "@type": "m_playlist"
    };
    return _result;
  }
  var result = {
    id: inset.id,
    name: inset.name,
    description: inset.description,
    sourceDef: ((_inset$playList$items = inset.playList.items[0]) === null || _inset$playList$items === void 0 ? void 0 : _inset$playList$items.source.createdFrom) || _common.STRINGID_NOT_SET,
    sharingMode: 'USE_SOURCE_DEF_VALUE',
    audioData: inset.audioData.map(function (a) {
      return {
        audioSettings: a.audioSettings,
        visibility: a.visibility
      };
    })[0] || defaultValue.audioData,
    videoData: inset.videoData.map(function (v) {
      return {
        position: v.position,
        visibility: v.visibility,
        movable: v.movable
      };
    })[0] || defaultValue.videoData,
    "@type": "m_source"
  };
  return result;
};
var updateLayoutHeader = exports.updateLayoutHeader = function updateLayoutHeader(request) {
  return (0, _utils.updateStandaloneData)(request, "globalLayouts");
};
var removeLayout = exports.removeLayout = function removeLayout(id) {
  var _removeStandaloneData;
  return (_removeStandaloneData = (0, _utils.removeStandaloneData)(id, "globalLayouts")) === null || _removeStandaloneData === void 0 ? void 0 : _removeStandaloneData.id;
};
var updateLayoutGeometry = exports.updateLayoutGeometry = function updateLayoutGeometry(request) {
  var foundMapper = (0, _mappers.getStandaloneMixerMappers)().find(function (m) {
    return m.id.value === request.mapperID.value;
  });
  if (!foundMapper) {
    return null;
  }
  var globalLayouts = getStandaloneMapperLayout();
  var foundIndex = globalLayouts.findIndex(function (item) {
    return item.id.value === request.id.value;
  });
  if (foundIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, globalLayouts[foundIndex]), {}, {
      createdFrom: request.mapperID,
      insets: foundMapper.insets,
      canvasSize: foundMapper.canvasSize,
      logicalGridData: foundMapper.logicalGridData,
      streamCompCreators: [],
      insetCompCreators: []
    });
    globalLayouts.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      globalLayouts: globalLayouts
    });
    return update;
  }
  return null;
};
var applyLayoutToOriginalMapper = exports.applyLayoutToOriginalMapper = function applyLayoutToOriginalMapper(id) {
  var globalLayouts = getStandaloneMapperLayout();
  var foundIndex = globalLayouts.findIndex(function (item) {
    return item.id.value === id.value;
  });
  if (foundIndex >= 0) {
    var mixerMappers = (0, _mappers.getStandaloneMixerMappers)();
    var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
      return item.id.value === globalLayouts[foundIndex].createdFrom.value;
    });
    if (foundMixerMapperIndex >= 0) {
      var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
        insets: globalLayouts[foundIndex].insetCompCreators.map(function (i) {
          return (0, _mappers.createInsetCompFromInsetCompCreator)(i, mixerMappers[foundMixerMapperIndex]);
        })
      });
      mixerMappers.splice(foundMixerMapperIndex, 1, update);
      (0, _store.updateStandaloneDataStore)({
        mixerMappers: mixerMappers
      });
      return {
        id: globalLayouts[foundIndex].id,
        mapperID: globalLayouts[foundIndex].createdFrom,
        insets: globalLayouts[foundIndex].insetCompCreators.map(function (i) {
          return (0, _mappers.createInsetCompFromInsetCompCreator)(i, mixerMappers[foundMixerMapperIndex]);
        }),
        streams: mixerMappers[foundMixerMapperIndex].streams
      };
    }
    return null;
  }
  return null;
};
var applyLayoutToMapper = exports.applyLayoutToMapper = function applyLayoutToMapper(request) {
  var globalLayouts = getStandaloneMapperLayout();
  var foundIndex = globalLayouts.findIndex(function (item) {
    return item.id.value === request.id.value;
  });
  if (foundIndex >= 0) {
    var mixerMappers = (0, _mappers.getStandaloneMixerMappers)();
    var foundMixerMapperIndex = mixerMappers.findIndex(function (item) {
      return item.id.value === request.mapperID.value;
    });
    if (foundMixerMapperIndex >= 0) {
      var update = _objectSpread(_objectSpread({}, mixerMappers[foundMixerMapperIndex]), {}, {
        insets: globalLayouts[foundIndex].insetCompCreators.map(function (i) {
          return (0, _mappers.createInsetCompFromInsetCompCreator)(i, mixerMappers[foundMixerMapperIndex]);
        })
      });
      mixerMappers.splice(foundMixerMapperIndex, 1, update);
      (0, _store.updateStandaloneDataStore)({
        mixerMappers: mixerMappers
      });
      return {
        id: globalLayouts[foundIndex].id,
        mapperID: request.mapperID,
        insets: globalLayouts[foundIndex].insetCompCreators.map(function (i) {
          return (0, _mappers.createInsetCompFromInsetCompCreator)(i, mixerMappers[foundMixerMapperIndex]);
        }),
        streams: mixerMappers[foundMixerMapperIndex].streams
      };
    }
    return null;
  }
  return null;
};
var createLayoutFromLayout = exports.createLayoutFromLayout = function createLayoutFromLayout(request) {
  return (0, _utils.updateStandaloneData)(request, "globalLayouts");
};
var applyLayoutToOtherLayout = exports.applyLayoutToOtherLayout = function applyLayoutToOtherLayout(request) {
  var globalLayouts = getStandaloneMapperLayout();
  var foundIndex = globalLayouts.findIndex(function (item) {
    return item.id.value === request.id.value;
  });
  if (foundIndex >= 0) {
    var layoutToCopy = globalLayouts.find(function (l) {
      return l.id.value === request.layoutID.value;
    });
    if (layoutToCopy) {
      var update = _objectSpread(_objectSpread({}, layoutToCopy), {}, {
        id: globalLayouts[foundIndex].id,
        name: globalLayouts[foundIndex].name,
        description: globalLayouts[foundIndex].description,
        createdFrom: globalLayouts[foundIndex].createdFrom,
        createInstant: globalLayouts[foundIndex].createInstant
      });
      globalLayouts.splice(foundIndex, 1, update);
      (0, _store.updateStandaloneDataStore)({
        globalLayouts: globalLayouts
      });
      return update;
    }
  }
  return null;
};
var updateLayoutGrid = exports.updateLayoutGrid = function updateLayoutGrid(request) {
  return (0, _utils.updateStandaloneData)(request, "globalLayouts");
};
var updateLayoutInsets = exports.updateLayoutInsets = function updateLayoutInsets(request) {
  var globalLayouts = getStandaloneMapperLayout();
  var foundIndex = globalLayouts.findIndex(function (item) {
    return item.id.value === request.id.value;
  });
  if (foundIndex >= 0) {
    var updatedInsets = globalLayouts[foundIndex].insetCompCreators.filter(function (i) {
      return request.remove.findIndex(function (r) {
        return r.value === i.id.value;
      }) < 0;
    }).map(function (i) {
      return _objectSpread(_objectSpread({}, i), request.update.find(function (u) {
        return u.id.value === i.id.value;
      }) || {});
    });
    var update = _objectSpread(_objectSpread({}, globalLayouts[foundIndex]), {}, {
      insetCompCreators: [].concat(_toConsumableArray(updatedInsets), _toConsumableArray(request.update.filter(function (i) {
        return updatedInsets.findIndex(function (u) {
          return u.id.value === i.id.value;
        }) < 0;
      })))
    });
    globalLayouts.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      globalLayouts: globalLayouts
    });
    return update;
  }
  return null;
};
var updateLayoutCanvasSize = exports.updateLayoutCanvasSize = function updateLayoutCanvasSize(request) {
  return (0, _utils.updateStandaloneData)(request, "globalLayouts");
};