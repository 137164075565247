"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePassiveCollection = exports.updateLayoutMappingCollection = exports.updateLayoutMapping = exports.updateDestinationMappingCollection = exports.updateDestinationMapping = exports.stopPassiveCollection = exports.startPassiveCollection = exports.startLayoutMappingCollection = exports.startDestinationCollection = exports.removePassiveCollection = exports.removeLayoutMappingCollection = exports.removeLayoutMapping = exports.removeDestinationMappingCollection = exports.removeDestinationMapping = exports.getStandalonePassiveCollections = exports.getStandaloneLayoutMappings = exports.getStandaloneLayoutMappingCollections = exports.getStandaloneDestinationMappings = exports.getStandaloneDestinationMappingCollections = exports.createPassiveCollection = exports.createLayoutMappingCollection = exports.createLayoutMapping = exports.createDestinationMappingCollection = exports.createDestinationMapping = void 0;
var _MappingGroups = require("../../models/mapping/MappingGroups");
var _cdm = require("./cdm");
var _mapperLayout = require("./mapperLayout");
var _mappers = require("./mappers");
var _utils = require("./utils");
// Custom Preset
var getStandalonePassiveCollections = exports.getStandalonePassiveCollections = function getStandalonePassiveCollections() {
  return (0, _utils.getStandaloneData)("passiveCollections");
};
var createPassiveCollection = exports.createPassiveCollection = function createPassiveCollection(update) {
  return (0, _utils.createStandaloneData)(update, "passiveCollections");
};
var updatePassiveCollection = exports.updatePassiveCollection = function updatePassiveCollection(update) {
  return (0, _utils.updateStandaloneData)(update, "passiveCollections");
};
var removePassiveCollection = exports.removePassiveCollection = function removePassiveCollection(id) {
  return (0, _utils.removeStandaloneData)(id, "passiveCollections");
};
var startPassiveCollection = exports.startPassiveCollection = function startPassiveCollection(id) {
  var _getStandalonePassive;
  var passiveMappers = ((_getStandalonePassive = getStandalonePassiveCollections().find(function (c) {
    return c.id.value === id.value;
  })) === null || _getStandalonePassive === void 0 ? void 0 : _getStandalonePassive.mappers) || [];
  return passiveMappers.map(function (p) {
    return (0, _mappers.startPassiveMapper)(p);
  });
};
var stopPassiveCollection = exports.stopPassiveCollection = function stopPassiveCollection(id) {
  var _getStandalonePassive2;
  var passiveMappers = ((_getStandalonePassive2 = getStandalonePassiveCollections().find(function (c) {
    return c.id.value === id.value;
  })) === null || _getStandalonePassive2 === void 0 ? void 0 : _getStandalonePassive2.mappers) || [];
  return passiveMappers.map(function (p) {
    return (0, _mappers.stopPassiveMapper)(p);
  });
};

// Destination Preset
var getStandaloneDestinationMappings = exports.getStandaloneDestinationMappings = function getStandaloneDestinationMappings() {
  return (0, _utils.getStandaloneData)("destinationMappings");
};
var createDestinationMapping = exports.createDestinationMapping = function createDestinationMapping(update) {
  return (0, _utils.createStandaloneData)(update, "destinationMappings");
};
var updateDestinationMapping = exports.updateDestinationMapping = function updateDestinationMapping(update) {
  return (0, _utils.updateStandaloneData)(update, "destinationMappings");
};
var removeDestinationMapping = exports.removeDestinationMapping = function removeDestinationMapping(id) {
  return (0, _utils.removeStandaloneData)(id, "destinationMappings");
};
var getStandaloneDestinationMappingCollections = exports.getStandaloneDestinationMappingCollections = function getStandaloneDestinationMappingCollections() {
  return (0, _utils.getStandaloneData)("destinationMappingCollections");
};
var createDestinationMappingCollection = exports.createDestinationMappingCollection = function createDestinationMappingCollection(update) {
  return (0, _utils.createStandaloneData)(update, "destinationMappingCollections");
};
var updateDestinationMappingCollection = exports.updateDestinationMappingCollection = function updateDestinationMappingCollection(update) {
  return (0, _utils.updateStandaloneData)(update, "destinationMappingCollections");
};
var removeDestinationMappingCollection = exports.removeDestinationMappingCollection = function removeDestinationMappingCollection(id) {
  return (0, _utils.removeStandaloneData)(id, "destinationMappingCollections");
};
var startDestinationCollection = exports.startDestinationCollection = function startDestinationCollection(id) {
  var _getStandaloneDestina;
  var destinationMappings = ((_getStandaloneDestina = getStandaloneDestinationMappingCollections().find(function (c) {
    return c.id.value === id.value;
  })) === null || _getStandaloneDestina === void 0 ? void 0 : _getStandaloneDestina.destinationMappings) || [];
  return destinationMappings.map(function (p) {
    return startDestinationMapping(p);
  });
};
var startDestinationMapping = function startDestinationMapping(id) {
  var destinationMapping = getStandaloneDestinationMappings();
  var found = destinationMapping.find(function (item) {
    return item.id.value === id.value;
  });
  if (found) {
    var foundMapper = (0, _mappers.findMapper)(found.mapper);
    if (foundMapper) {
      var mappedDestinations = (0, _cdm.destinationSetToDestinationIDSet)((0, _cdm.destinationIDsToDestinationSet)(found.destinations));
      var otherMappersInSameApp = (0, _mappers.findMappersOfSameApp)(foundMapper);
      otherMappersInSameApp.forEach(function (m) {
        return (0, _mappers.updateMapperDestinations)({
          id: m.id,
          addDestinations: _MappingGroups.EMPTY_DESTINATION_IDS,
          removeDestinations: mappedDestinations
        });
      });
      (0, _mappers.updateMapperDestinations)({
        id: foundMapper.id,
        addDestinations: mappedDestinations,
        removeDestinations: _MappingGroups.EMPTY_DESTINATION_IDS
      });
      return (0, _mappers.findMapper)(found.mapper);
    }
  }
  return null;
};

// Layout Presets
var getStandaloneLayoutMappings = exports.getStandaloneLayoutMappings = function getStandaloneLayoutMappings() {
  return (0, _utils.getStandaloneData)("layoutMappings");
};
var createLayoutMapping = exports.createLayoutMapping = function createLayoutMapping(update) {
  return (0, _utils.createStandaloneData)(update, "layoutMappings");
};
var updateLayoutMapping = exports.updateLayoutMapping = function updateLayoutMapping(update) {
  return (0, _utils.updateStandaloneData)(update, "layoutMappings");
};
var removeLayoutMapping = exports.removeLayoutMapping = function removeLayoutMapping(id) {
  return (0, _utils.removeStandaloneData)(id, "layoutMappings");
};
var getStandaloneLayoutMappingCollections = exports.getStandaloneLayoutMappingCollections = function getStandaloneLayoutMappingCollections() {
  return (0, _utils.getStandaloneData)("layoutMappingCollections");
};
var createLayoutMappingCollection = exports.createLayoutMappingCollection = function createLayoutMappingCollection(update) {
  return (0, _utils.createStandaloneData)(update, "layoutMappingCollections");
};
var updateLayoutMappingCollection = exports.updateLayoutMappingCollection = function updateLayoutMappingCollection(update) {
  return (0, _utils.updateStandaloneData)(update, "layoutMappingCollections");
};
var removeLayoutMappingCollection = exports.removeLayoutMappingCollection = function removeLayoutMappingCollection(id) {
  return (0, _utils.removeStandaloneData)(id, "layoutMappingCollections");
};
var startLayoutMappingCollection = exports.startLayoutMappingCollection = function startLayoutMappingCollection(id) {
  var _getStandaloneLayoutM;
  var mappings = ((_getStandaloneLayoutM = getStandaloneLayoutMappingCollections().find(function (c) {
    return c.id.value === id.value;
  })) === null || _getStandaloneLayoutM === void 0 ? void 0 : _getStandaloneLayoutM.layoutMappings) || [];
  return mappings.map(function (p) {
    return startLayoutMapping(p);
  });
};
var startLayoutMapping = function startLayoutMapping(id) {
  var data = getStandaloneLayoutMappings();
  var found = data.find(function (item) {
    return item.id.value === id.value;
  });
  if (found) {
    found.destinations.forEach(function (d) {
      return (0, _mapperLayout.applyLayoutToMapper)({
        id: found.layout,
        mapperID: d
      });
    });
  }
};