"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SOURCE_SPECS = exports.SOURCE_ASSETS = exports.SOURCES = exports.PLAYLISTS = void 0;
var SOURCE_ASSETS = exports.SOURCE_ASSETS = [];
var SOURCE_SPECS = exports.SOURCE_SPECS = [{
  header: {
    id: {
      value: "Network_Streamer",
      name: "Network Streamer"
    },
    name: "Network Streamer",
    userVisible: true,
    directSource: true,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Network_Streamer_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-network-streamer/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-network-streamer"
    }],
    options: ["/usr/share/userful-plugin-network-streamer/icons/default.png"],
    optionValues: ["/usr/share/userful-plugin-network-streamer/icons/default.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Network_Streamer_Protocol"
    },
    type: "SELECT",
    name: "Protocol",
    index: 1,
    required: true,
    help: "",
    "default": "rtsp://",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "widget-width",
      value: "100px"
    }],
    options: ["rtsp://", "http://", "https://"],
    optionValues: ["rtsp://", "http://", "https://"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Network_Streamer_Server_Path"
    },
    type: "TEXT",
    name: "Server Path",
    index: 2,
    required: true,
    help: "Do not include the protocol here!",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Network_Streamer_Buffer"
    },
    type: "NUMBER",
    name: "Buffer",
    index: 3,
    required: false,
    help: "",
    "default": "800",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Network_Streamer_Username"
    },
    type: "TEXT",
    name: "Username",
    index: 4,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "20"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Network_Streamer_Password"
    },
    type: "PASSWORD",
    name: "Password",
    index: 5,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "20"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Network_Streamer_help"
    },
    type: "HELP",
    name: "",
    index: 6,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/Station_Mapping/Network_Streamer"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Remote_Desktop",
      name: "Remote Desktop"
    },
    name: "Remote Desktop",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Remote_Desktop_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-remote-desktop/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-remote-desktop"
    }],
    options: ["/usr/share/userful-plugin-remote-desktop/icons/FreeRDP_Icon.png", "/usr/share/userful-plugin-remote-desktop/icons/rdesktop.png", "/usr/share/userful-plugin-remote-desktop/icons/system-logo.png"],
    optionValues: ["/usr/share/userful-plugin-remote-desktop/icons/FreeRDP_Icon.png", "/usr/share/userful-plugin-remote-desktop/icons/rdesktop.png", "/usr/share/userful-plugin-remote-desktop/icons/system-logo.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Remote_Desktop_Server"
    },
    type: "TEXT",
    name: "Server",
    index: 1,
    required: true,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Remote_Desktop_Port"
    },
    type: "NUMBER",
    name: "Port",
    index: 2,
    required: true,
    help: "",
    "default": "3389",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "maximum",
      value: "65536"
    }, {
      name: "minimum",
      value: "1"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Remote_Desktop_Username"
    },
    type: "TEXT",
    name: "Username",
    index: 3,
    required: false,
    help: "To include the station number in the username, include the text \"@STATION@\". ",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Remote_Desktop_Password"
    },
    type: "PASSWORD",
    name: "Password",
    index: 4,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Remote_Desktop_Options"
    },
    type: "TEXT_AREA",
    name: "Options",
    index: 5,
    required: false,
    help: "",
    "default": "-sec-nla /rfx /rfx-mode:v /gdi:hw /network:broadband /f /cert-ignore /sound",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Remote_Desktop_help"
    },
    type: "HELP",
    name: "",
    index: 6,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/Station_Mapping/RDP_Client"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Tableau",
      name: "Tableau"
    },
    name: "Tableau",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Tableau_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-tableau/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-tableau"
    }],
    options: ["/usr/share/userful-plugin-tableau/icons/system-logo.png", "/usr/share/userful-plugin-tableau/icons/tab-thumbnail.png"],
    optionValues: ["/usr/share/userful-plugin-tableau/icons/system-logo.png", "/usr/share/userful-plugin-tableau/icons/tab-thumbnail.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_Visualization_URL"
    },
    type: "URL",
    name: "Visualization URL",
    index: 1,
    required: true,
    help: "The visualization URL to embed.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_Authentication_Type"
    },
    type: "SELECT",
    name: "Authentication Type",
    index: 2,
    required: true,
    help: "Select the authentication type for embedding the visualization. \"Tableau Personal Access Token\" and \"Tableau Password\" are for Tableau admins only.",
    "default": "Tableau Public",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "optionValues",
      value: "[\"Tableau Personal Access Token\",\"Tableau Password\",\"Tableau Public\", \"Connected App\"]"
    }],
    options: ["Tableau Personal Access Token", "Tableau Password", "Tableau Public", "Connected App"],
    optionValues: ["Tableau Personal Access Token", "Tableau Password", "Tableau Public", "Connected App"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_Account_Email_/_Username"
    },
    type: "TEXT",
    name: "Account Email / Username",
    index: 3,
    required: true,
    help: "Your Tableau account's email address or username.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_PAT_Token_Name"
    },
    type: "TEXT",
    name: "PAT Token Name",
    index: 4,
    required: true,
    help: "Name of the Personal Access Token.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_Account_Password"
    },
    type: "PASSWORD",
    name: "Account Password",
    index: 5,
    required: true,
    help: "Your Tableau account's password.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_PAT_Token_Secret"
    },
    type: "PASSWORD",
    name: "PAT Token Secret",
    index: 6,
    required: true,
    help: "Secret of the Personal Access Token.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_Connected_App's_Client_ID"
    },
    type: "TEXT",
    name: "Connected App's Client ID",
    index: 7,
    required: true,
    help: "Client ID of the Connected App - Direct Trust. Contact your Tableau admin to get this.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_Connected_App's_Secret_ID"
    },
    type: "TEXT",
    name: "Connected App's Secret ID",
    index: 8,
    required: true,
    help: "Secret ID of the Connected App - Direct Trust. Contact your Tableau admin to get this.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_Connected_App's_Secret_Value"
    },
    type: "PASSWORD",
    name: "Connected App's Secret Value",
    index: 9,
    required: true,
    help: "Secret Value of the Connected App - Direct Trust. Contact your Tableau admin to get this.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_framerate"
    },
    type: "SELECT",
    name: "framerate",
    index: 10,
    required: false,
    help: "The Frames per second the Tableau Visualizaton will captured at.",
    "default": "30",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["0.1", "0.5", "1", "5", "10", "25", "30", "45", "60"],
    optionValues: ["0.1", "0.5", "1", "5", "10", "25", "30", "45", "60"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Tableau_help"
    },
    type: "HELP",
    name: "",
    index: 11,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/Tableau"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "uClient_Local_Browser",
      name: "uClient Local Browser"
    },
    name: "uClient Local Browser",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "uClient_Local_Browser_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-uclient-webbrowser/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-uclient-webbrowser"
    }],
    options: ["/usr/share/userful-plugin-uclient-webbrowser/icons/system-logo.png", "/usr/share/userful-plugin-uclient-webbrowser/icons/uclient-webbrowser.png"],
    optionValues: ["/usr/share/userful-plugin-uclient-webbrowser/icons/system-logo.png", "/usr/share/userful-plugin-uclient-webbrowser/icons/uclient-webbrowser.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "uClient_Local_Browser_Homepage"
    },
    type: "TEXT",
    name: "Homepage",
    index: 1,
    required: true,
    help: "",
    "default": "http://www.userful.com/welcome",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "40"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "uClient_Local_Browser_Show_Toolbar"
    },
    type: "CHECKBOX",
    name: "Show Toolbar",
    index: 2,
    required: false,
    help: "",
    "default": "false",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "uClient_Local_Browser_Browser_Width"
    },
    type: "TEXT",
    name: "Browser Width",
    index: 4,
    required: false,
    help: "",
    "default": "1920",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "40"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "uClient_Local_Browser_Browser_Height"
    },
    type: "TEXT",
    name: "Browser Height",
    index: 5,
    required: false,
    help: "",
    "default": "1080",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "40"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "uClient_Local_Browser_Refresh_Interval"
    },
    type: "NUMBER",
    name: "Refresh Interval",
    index: 6,
    required: false,
    help: "",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "3"
    }, {
      name: "maximum",
      value: "3153600000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "uClient_Local_Browser_Max_Loading_Time"
    },
    type: "NUMBER",
    name: "Max Loading Time",
    index: 7,
    required: false,
    help: "",
    "default": "10",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "3"
    }, {
      name: "maximum",
      value: "3153600000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "uClient_Local_Browser_help"
    },
    type: "HELP",
    name: "",
    index: 8,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "https://support.userful.com/Docs/Mapping/uClient_Local_Browser"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Control_Center",
      name: "Control Center"
    },
    name: "Control Center",
    userVisible: false,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Control_Center_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-control-center/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-control-center"
    }],
    options: ["/usr/share/userful-plugin-control-center/icons/settings.png", "/usr/share/userful-plugin-control-center/icons/system-logo.png"],
    optionValues: ["/usr/share/userful-plugin-control-center/icons/settings.png", "/usr/share/userful-plugin-control-center/icons/system-logo.png"],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "HDMI_Capture",
      name: "HDMI Capture"
    },
    name: "HDMI Capture",
    userVisible: true,
    directSource: true,
    sharable: false,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "HDMI_Capture_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-hdmi-capture/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-hdmi-capture"
    }],
    options: ["/usr/share/userful-plugin-hdmi-capture/icons/default.jpg", "/usr/share/userful-plugin-hdmi-capture/icons/default.png"],
    optionValues: ["/usr/share/userful-plugin-hdmi-capture/icons/default.jpg", "/usr/share/userful-plugin-hdmi-capture/icons/default.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "HDMI_Capture_bm_connection"
    },
    type: "SELECT",
    name: "bm_connection",
    index: 2,
    required: true,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["0: Auto", "1: SDI", "2: HDMI", "3: Optical SDI", "4: Component", "5: Composite", "6: S-Video"],
    optionValues: ["0: Auto", "1: SDI", "2: HDMI", "3: Optical SDI", "4: Component", "5: Composite", "6: S-Video"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "HDMI_Capture_use_xclient"
    },
    type: "CHECKBOX",
    name: "use_xclient",
    index: 3,
    required: false,
    help: "Use XClient to play hdmi input.",
    "default": "false",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "HDMI_Capture_crop_left"
    },
    type: "NUMBER",
    name: "crop_left",
    index: 4,
    required: true,
    help: "Pixels to crop at left",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "10000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "HDMI_Capture_crop_right"
    },
    type: "NUMBER",
    name: "crop_right",
    index: 5,
    required: true,
    help: "Pixels to crop at right",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "10000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "HDMI_Capture_crop_top"
    },
    type: "NUMBER",
    name: "crop_top",
    index: 6,
    required: true,
    help: "Pixels to crop at top",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "10000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "HDMI_Capture_crop_bottom"
    },
    type: "NUMBER",
    name: "crop_bottom",
    index: 7,
    required: true,
    help: "Pixels to crop at bottom",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "10000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "HDMI_Capture_help"
    },
    type: "HELP",
    name: "",
    index: 8,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/Station_Mapping/Blackmagic_DeckLink_Capture"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "HDMI_Capture_unavailable"
    },
    type: "UNAVAILABLE",
    name: "",
    index: 9,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "value",
      value: "674"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: [{
    sourceParamName: "Input",
    cardinality: "ONE",
    required: true,
    sourceAssetType: "DECKLINK",
    paramFormat: "DECKLINK"
  }]
}, {
  header: {
    id: {
      value: "Web_Browser",
      name: "Web Browser"
    },
    name: "Web Browser",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Web_Browser_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-webbrowser/icons",
    "default": "/usr/share/userful-plugin-webbrowser/icons/webbrowser.png",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-webbrowser"
    }],
    options: ["/usr/share/userful-plugin-webbrowser/icons/chrome.png", "/usr/share/userful-plugin-webbrowser/icons/cloud-icon.png", "/usr/share/userful-plugin-webbrowser/icons/firefox.png", "/usr/share/userful-plugin-webbrowser/icons/google.png", "/usr/share/userful-plugin-webbrowser/icons/settings.png", "/usr/share/userful-plugin-webbrowser/icons/system-logo.png", "/usr/share/userful-plugin-webbrowser/icons/webbrowser.png"],
    optionValues: ["/usr/share/userful-plugin-webbrowser/icons/chrome.png", "/usr/share/userful-plugin-webbrowser/icons/cloud-icon.png", "/usr/share/userful-plugin-webbrowser/icons/firefox.png", "/usr/share/userful-plugin-webbrowser/icons/google.png", "/usr/share/userful-plugin-webbrowser/icons/settings.png", "/usr/share/userful-plugin-webbrowser/icons/system-logo.png", "/usr/share/userful-plugin-webbrowser/icons/webbrowser.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_Browser"
    },
    type: "SELECT",
    name: "Browser",
    index: 1,
    required: true,
    help: "Only Firefox and Google Chrome are supported. If Chrome is installed, additional browser profile types (kiosk and cloud session) are available.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "onchange",
      value: "getAllConfigs"
    }],
    options: ["Chrome", "Chrome: Kiosk", "Firefox", "Firefox: Fullscreen", "WebKit (light-weight basic functionality)"],
    optionValues: ["Chrome", "Chrome: Kiosk", "Firefox", "Firefox: Fullscreen", "WebKit (light-weight basic functionality)"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_URL"
    },
    type: "TEXT",
    name: "URL",
    index: 2,
    required: true,
    help: "",
    "default": "http://www.userful.com/welcome",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "40"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_Refresh_Interval"
    },
    type: "NUMBER",
    name: "Refresh Interval",
    index: 3,
    required: false,
    help: "",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "3"
    }, {
      name: "maximum",
      value: "3153600000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_framerate"
    },
    type: "SELECT",
    name: "framerate",
    index: 4,
    required: false,
    help: "",
    "default": "30",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["0.1", "0.5", "1", "5", "10", "25", "30", "45", "60"],
    optionValues: ["0.1", "0.5", "1", "5", "10", "25", "30", "45", "60"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_Show_mouse_pointer"
    },
    type: "CHECKBOX",
    name: "Show mouse pointer",
    index: 5,
    required: false,
    help: "Hides the mouse pointer after a small delay. The pointer will show again if the mouse is moved.",
    "default": "false",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_Session_Persistence"
    },
    type: "RADIO",
    name: "Session Persistence",
    index: 6,
    required: true,
    help: "",
    "default": "Discard",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["Retain", "Discard"],
    optionValues: ["Retain", "Discard"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_help"
    },
    type: "HELP",
    name: "",
    index: 7,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "https://support.userful.com/Docs/Mapping/Web_Browser"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Signage_Player",
      name: "Signage Player"
    },
    name: "Signage Player",
    userVisible: true,
    directSource: true,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Signage_Player_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-signage-player/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-signage-player"
    }],
    options: ["/usr/share/userful-plugin-signage-player/icons/MediaPlayer.png"],
    optionValues: ["/usr/share/userful-plugin-signage-player/icons/MediaPlayer.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Signage_Player_Slideshow_interval"
    },
    type: "NUMBER",
    name: "Slideshow interval",
    index: 2,
    required: false,
    help: "",
    "default": "5",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "300"
    }, {
      name: "minimum",
      value: "5"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Signage_Player_help"
    },
    type: "HELP",
    name: "",
    index: 3,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/Station_Mapping/Signage_Player"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: [{
    sourceParamName: "Playlist",
    cardinality: "MANY",
    required: true,
    sourceAssetType: "MEDIA_FILE",
    paramFormat: "MEDIA_FILE_LIST"
  }]
}, {
  header: {
    id: {
      value: "VNC_Viewer",
      name: "VNC Viewer"
    },
    name: "VNC Viewer",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "VNC_Viewer_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-vnc/icons",
    "default": "/usr/share/userful-plugin-vnc/icons/vnc.png",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-vnc"
    }],
    options: ["/usr/share/userful-plugin-vnc/icons/system-logo.png", "/usr/share/userful-plugin-vnc/icons/vnc.png"],
    optionValues: ["/usr/share/userful-plugin-vnc/icons/system-logo.png", "/usr/share/userful-plugin-vnc/icons/vnc.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "VNC_Viewer_vnc_server"
    },
    type: "TEXT",
    name: "vnc_server",
    index: 1,
    required: true,
    help: "Enter the IP address of the VNC Server.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "VNC_Viewer_vnc_port"
    },
    type: "NUMBER",
    name: "vnc_port",
    index: 2,
    required: true,
    help: "Enter the TCP/IP port number. e.g. 5900",
    "default": "5900",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "65535"
    }, {
      name: "minimum",
      value: "1"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "VNC_Viewer_vnc_password"
    },
    type: "PASSWORD",
    name: "vnc_password",
    index: 3,
    required: false,
    help: "Enter the password of the VNC Server.",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "20"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "VNC_Viewer_vnc_fullscreen"
    },
    type: "CHECKBOX",
    name: "vnc_fullscreen",
    index: 4,
    required: false,
    help: "Enable it to scale to full screen.",
    "default": "true",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "VNC_Viewer_vnc_viewonly"
    },
    type: "CHECKBOX",
    name: "vnc_viewonly",
    index: 5,
    required: false,
    help: "Disable it for interaction using mouse and keyboard.",
    "default": "true",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "VNC_Viewer_help"
    },
    type: "HELP",
    name: "",
    index: 6,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "https://support.userful.com/Docs/Mapping/Desktop_Streamer"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Power_BI",
      name: "Power BI"
    },
    name: "Power BI",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Power_BI_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-powerbi/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-powerbi"
    }],
    options: ["/usr/share/userful-plugin-powerbi/icons/bi-thumbnail.png", "/usr/share/userful-plugin-powerbi/icons/system-logo.png"],
    optionValues: ["/usr/share/userful-plugin-powerbi/icons/bi-thumbnail.png", "/usr/share/userful-plugin-powerbi/icons/system-logo.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Power_BI_Account_Email"
    },
    type: "EMAIL",
    name: "Account Email",
    index: 1,
    required: true,
    help: "Power BI Account Email",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Power_BI_Account_Password"
    },
    type: "PASSWORD",
    name: "Account Password",
    index: 2,
    required: true,
    help: "Power BI Account Password",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Power_BI_Client_ID"
    },
    type: "TEXT",
    name: "Client ID",
    index: 3,
    required: true,
    help: "Power BI Client ID (upon app setup)",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Power_BI_Report_URL"
    },
    type: "URL",
    name: "Report URL",
    index: 4,
    required: true,
    help: "URL from a shared workspace ending in /ReportSection",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Power_BI_framerate"
    },
    type: "SELECT",
    name: "framerate",
    index: 5,
    required: false,
    help: "The Frames per second the Power BI Report will captured at",
    "default": "30",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["0.1", "0.5", "1", "5", "10", "25", "30", "45", "60"],
    optionValues: ["0.1", "0.5", "1", "5", "10", "25", "30", "45", "60"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Power_BI_help"
    },
    type: "HELP",
    name: "",
    index: 6,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/PowerBI"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Emerald_Signage",
      name: "Emerald Signage"
    },
    name: "Emerald Signage",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Emerald_Signage_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-emerald-signage/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-emerald-signage"
    }],
    options: ["/usr/share/userful-plugin-emerald-signage/icons/engage-signage.png", "/usr/share/userful-plugin-emerald-signage/icons/system-logo.png"],
    optionValues: ["/usr/share/userful-plugin-emerald-signage/icons/engage-signage.png", "/usr/share/userful-plugin-emerald-signage/icons/system-logo.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Emerald_Signage_Engage_Manual_Setup"
    },
    type: "INSTRUCTION",
    name: "Engage Manual Setup",
    index: 1,
    required: false,
    help: "",
    "default": "Engage Signage requires you to assign the source to a Destination to display a pairing code then <a href=\"/engage/?defaultcontent=cmsTool\" target=\"_blank\">login to your Engage account</a> to link the session.",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Emerald_Signage_Refresh_Interval"
    },
    type: "NUMBER",
    name: "Refresh Interval",
    index: 2,
    required: false,
    help: "",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "3"
    }, {
      name: "maximum",
      value: "3153600000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Emerald_Signage_framerate"
    },
    type: "SELECT",
    name: "framerate",
    index: 3,
    required: false,
    help: "",
    "default": "30",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["0.1", "0.5", "1", "5", "10", "25", "30", "45", "60"],
    optionValues: ["0.1", "0.5", "1", "5", "10", "25", "30", "45", "60"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Emerald_Signage_Show_mouse_pointer"
    },
    type: "CHECKBOX",
    name: "Show mouse pointer",
    index: 4,
    required: false,
    help: "Hides the mouse pointer after a small delay. The pointer will show again if the mouse is moved.",
    "default": "false",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Emerald_Signage_Session_Persistence"
    },
    type: "RADIO",
    name: "Session Persistence",
    index: 5,
    required: true,
    help: "",
    "default": "Retain",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["Retain", "Discard"],
    optionValues: ["Retain", "Discard"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Emerald_Signage_help"
    },
    type: "HELP",
    name: "",
    index: 6,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "https://support.userful.com/Docs/Mapping/Engage"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "V4L_Capture",
      name: "V4L Capture"
    },
    name: "V4L Capture",
    userVisible: true,
    directSource: true,
    sharable: false,
    requiresLiveData: true,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "V4L_Capture_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-v4l-capture/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-v4l-capture"
    }],
    options: ["/usr/share/userful-plugin-v4l-capture/icons/default.jpg", "/usr/share/userful-plugin-v4l-capture/icons/default.png"],
    optionValues: ["/usr/share/userful-plugin-v4l-capture/icons/default.jpg", "/usr/share/userful-plugin-v4l-capture/icons/default.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_Device"
    },
    type: "SELECT",
    name: "Device",
    index: 1,
    required: true,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "10"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_Scaling"
    },
    type: "RADIO",
    name: "Scaling",
    index: 2,
    required: true,
    help: "",
    "default": "outside",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "optionsIcons",
      value: "[\"zoom-to-full-screen.png\",\"scale-to-fit.png\",\"stretch-to-full-screen.png\"]"
    }],
    options: ["outside", "inside", "full"],
    optionValues: ["outside", "inside", "full"],
    optionIcons: ["zoom-to-full-screen.png", "scale-to-fit.png", "stretch-to-full-screen.png"],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_Disable_audio"
    },
    type: "CHECKBOX",
    name: "Disable audio",
    index: 3,
    required: false,
    help: "",
    "default": "true",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_crop_left"
    },
    type: "NUMBER",
    name: "crop_left",
    index: 4,
    required: true,
    help: "Pixels to crop at left",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "10000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_crop_right"
    },
    type: "NUMBER",
    name: "crop_right",
    index: 5,
    required: true,
    help: "Pixels to crop at right",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "10000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_crop_top"
    },
    type: "NUMBER",
    name: "crop_top",
    index: 6,
    required: true,
    help: "Pixels to crop at top",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "10000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_crop_bottom"
    },
    type: "NUMBER",
    name: "crop_bottom",
    index: 7,
    required: true,
    help: "Pixels to crop at bottom",
    "default": "0",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "5"
    }, {
      name: "maximum",
      value: "10000"
    }, {
      name: "minimum",
      value: "0"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_help"
    },
    type: "HELP",
    name: "",
    index: 8,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/Station_Mapping/Video_Capture"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "V4L_Capture_unavailable"
    },
    type: "UNAVAILABLE",
    name: "",
    index: 9,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "value",
      value: "True"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Sapphire_Spaces_-_Beta",
      name: "Sapphire Spaces - Beta"
    },
    name: "Sapphire Spaces - Beta",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Sapphire_Spaces_-_Beta_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-sapphire/icons",
    "default": "/usr/share/userful-plugin-sapphire/icons/sapphire.png",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-sapphire"
    }],
    options: ["/usr/share/userful-plugin-sapphire/icons/sapphire.png", "/usr/share/userful-plugin-sapphire/icons/system-logo.png"],
    optionValues: ["/usr/share/userful-plugin-sapphire/icons/sapphire.png", "/usr/share/userful-plugin-sapphire/icons/system-logo.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Sapphire_Spaces_-_Beta_Meeting_Room_Name"
    },
    type: "TEXT",
    name: "Meeting Room Name",
    index: 1,
    required: true,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Sapphire_Spaces_-_Beta_Air_Gapped"
    },
    type: "CHECKBOX",
    name: "Air Gapped",
    index: 2,
    required: false,
    help: "",
    "default": "False",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Sapphire_Spaces_-_Beta_Link"
    },
    type: "TEXT",
    name: "Link",
    index: 3,
    required: true,
    help: "The Link to open Sapphire portal",
    "default": "https://meet.tony.userful.dev/",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Sapphire_Spaces_-_Beta_Message"
    },
    type: "TEXT",
    name: "Message",
    index: 4,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Sapphire_Spaces_-_Beta_Background"
    },
    type: "TEXT",
    name: "Background",
    index: 5,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Sapphire_Spaces_-_Beta_help"
    },
    type: "HELP",
    name: "",
    index: 6,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/Station_Mapping/Sapphire"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Web_Browser_YouTube",
      name: "Web Browser YouTube"
    },
    name: "Web Browser YouTube",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Web_Browser_YouTube_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-webbrowser-youtube/icons",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-webbrowser-youtube"
    }],
    options: ["/usr/share/userful-plugin-webbrowser-youtube/icons/YouTube_full-color_icon.png", "/usr/share/userful-plugin-webbrowser-youtube/icons/system-logo.png"],
    optionValues: ["/usr/share/userful-plugin-webbrowser-youtube/icons/YouTube_full-color_icon.png", "/usr/share/userful-plugin-webbrowser-youtube/icons/system-logo.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_YouTube_YouTube_URL_or_video_ID"
    },
    type: "TEXT",
    name: "YouTube URL or video ID",
    index: 1,
    required: true,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "40"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_YouTube_Loop_video"
    },
    type: "CHECKBOX",
    name: "Loop video",
    index: 2,
    required: false,
    help: "Replay the video after it finishes, some YouTube videos may not support Looping",
    "default": "false",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Web_Browser_YouTube_help"
    },
    type: "HELP",
    name: "",
    index: 3,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "https://support.userful.com/Docs/Mapping/Web_Browser"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}, {
  header: {
    id: {
      value: "Program_Runner",
      name: "Program Runner"
    },
    name: "Program Runner",
    userVisible: true,
    directSource: false,
    sharable: true,
    requiresLiveData: false,
    recommendNVidia: false,
    requiresNVidia: false,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE"
  },
  params: [{
    id: {
      value: "Program_Runner_Icon"
    },
    type: "IMAGE_SELECT",
    name: "Icon",
    index: 0,
    required: true,
    help: "The following icons are retrieved from /usr/share/userful-plugin-program-runner/icons",
    "default": "/usr/share/userful-plugin-program-runner/icons/program-runner.png",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "path",
      value: "/html/icons/userful-plugin-program-runner"
    }],
    options: ["/usr/share/userful-plugin-program-runner/icons/program-runner.png", "/usr/share/userful-plugin-program-runner/icons/system-logo.png"],
    optionValues: ["/usr/share/userful-plugin-program-runner/icons/program-runner.png", "/usr/share/userful-plugin-program-runner/icons/system-logo.png"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Program_Runner_Command"
    },
    type: "TEXT",
    name: "Command",
    index: 1,
    required: true,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "size",
      value: "40"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Program_Runner_Window_Management"
    },
    type: "RADIO",
    name: "Window Management",
    index: 2,
    required: false,
    help: "",
    "default": "None",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["None", "Full screen", "Window borders"],
    optionValues: ["None", "Full screen", "Window borders"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Program_Runner_Hide_mouse_pointer"
    },
    type: "CHECKBOX",
    name: "Hide mouse pointer",
    index: 3,
    required: false,
    help: "",
    "default": "false",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Program_Runner_Session_persistence"
    },
    type: "RADIO",
    name: "Session persistence",
    index: 4,
    required: false,
    help: "",
    "default": "Discard",
    requiresLogicalSeat: false,
    additionalParams: [],
    options: ["Retain", "Discard"],
    optionValues: ["Retain", "Discard"],
    optionIcons: [],
    multiOptions: []
  }, {
    id: {
      value: "Program_Runner_help"
    },
    type: "HELP",
    name: "",
    index: 5,
    required: false,
    help: "",
    "default": "",
    requiresLogicalSeat: false,
    additionalParams: [{
      name: "url",
      value: "http://support.userful.com/Docs/Station_Mapping/Program_Runner"
    }],
    options: [],
    optionValues: [],
    optionIcons: [],
    multiOptions: []
  }],
  assetSpecs: []
}];
var SOURCES = exports.SOURCES = [];
var PLAYLISTS = exports.PLAYLISTS = [];