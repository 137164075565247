import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { apiDeleteUser } from "../../messages/api/gcmUserManagement";
import carbonStore from "../carbonStore";
import { TSliceManagementAction } from "../constellations/constellationsManager";
import { userManagementActions } from "../userManagement/userManagementSlice";
import { apiDeleteBackup } from "../../messages/api/gcmAPICallers";
import { BackupData } from "../../model/CarbonExternalModels";

export const deleteBackup = (backup: BackupData, type: TSliceManagementAction) => {
    if (type === "INTERNAL") {
        // carbonStore.dispatch(userManagementActions.removeUser(backup.id));
        apiDeleteBackup(getGlobalStates().keycloak.token, backup);
    }
};