"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPOND_UPDATE_PROXY_SETTINGS = exports.RESPOND_PROXY_SETTINGS = exports.REQUEST_UPDATE_PROXY_SETTINGS = exports.REQUEST_PROXY_SETTINGS = void 0;
var _messageModel = require("../../messageModel");
var PROXY = 2026;
var REQUEST_PROXY_SETTINGS = exports.REQUEST_PROXY_SETTINGS = new _messageModel.RequestMessageDefinition(PROXY, 0);
var REQUEST_UPDATE_PROXY_SETTINGS = exports.REQUEST_UPDATE_PROXY_SETTINGS = new _messageModel.RequestMessageDefinition(PROXY, 1);
var RESPOND_PROXY_SETTINGS = exports.RESPOND_PROXY_SETTINGS = new _messageModel.RespondMessageDefinition(PROXY, 0);
var RESPOND_UPDATE_PROXY_SETTINGS = exports.RESPOND_UPDATE_PROXY_SETTINGS = new _messageModel.RespondMessageDefinition(PROXY, 1);