import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useMount, useSetState, useTimeout } from "react-use";
import { MessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";
import { getGlobalStates, apiPost, setEventBus, initPostLogin, setGcmGlobalStateData, reSetGcmGlobalStateData } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { AxiosResponse } from "axios";
import Keycloak from "keycloak-js";
import { getUserRoles, gcmUserPermissions } from "userful-chronos-app-common-js/dist/authenticataion/gcm-roles";
import { redirectUriFor } from "userful-chronos-app-common-js/dist/routing";
import { InitLoading } from "userful-chronos-common-ui/dist";
import { requestInitStatus } from "../messages/api/gcmAPICallers";
import { AuthenticationContext, initAuthenticationState } from "./AuthenticationContext";

import { AppUser } from "userful-chronos-app-common-js/dist/models/postLoginData";
import { ChronosEventBus2 } from "userful-chronos-app-common-js/dist/eventbus/eventbus2";
import { KeycloakUser } from "userful-chronos-app-common-js/dist/models/keycloak-user";

import { REQUEST_POST_LOGIN } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/request/login";
import { RESPOND_POST_LOGIN } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/respond/login";
import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";

import { currentURL } from "../messages/api/gcmAPICallers";

const AUTHORIZE_USER = "/api/secure/usermgmt/user/authorize";
const UN_AUTHORIZE_USER = "/api/secure/usermgmt/user/unauthorize";

export const authUser = (token: string, keycloakUser: KeycloakUser) =>
    apiPost(`${currentURL}${AUTHORIZE_USER}`, keycloakUser, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const unAuthUser = (token: string, keycloakUser: KeycloakUser) =>
    apiPost(`${currentURL}${UN_AUTHORIZE_USER}`, keycloakUser, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

interface IProps {
    children: ReactNode;
    authenticate?: boolean;
}

export default function Authenticated({ children, authenticate = true }: IProps) {
    const [showChildren, setShowChildren] = useState(!authenticate);
    const [loggingOut, setLoggingOut] = useState(false);

    const [state, setState] = useSetState({
        ...initAuthenticationState,
    });
    const tryLoginInterval = useRef(null);
    const tryLoginAtInterval = () => {
        clearInterval(tryLoginInterval.current);
        tryLoginInterval.current = setInterval(login, 3000);
    };
    const getPostLoginDataInterval = useRef(null);
    const getPostLoginDataTask = () => {
        clearInterval(getPostLoginDataInterval.current);
        getPostLoginDataInterval.current = setInterval(() => {
            sendMsg(REQUEST_POST_LOGIN, {
                clientAppName: getGlobalStates().appID.value,
            });
        }, 2000);
    };
    const pendoInitialized = useRef(false);

    useEffect(() => {
        if (showChildren && authenticate && !tryLoginInterval.current && !isUserAuthorized()) {
            authenticateUser();
        }
    }, [authenticate]);

    useMount(() => {
        registerMsgHandler(RESPOND_POST_LOGIN, (result) => {
            console.debug(result);
            clearInterval(getPostLoginDataInterval.current);
            const data = JSON.parse(result);
            setState({ postLoginData: data });
            initPostLogin(data);
        });
        authenticateUser();
    });

    useEffect(() => {
        const { connected, canReconnect, keycloak } = state;
        const authorized = keycloak && keycloak.authenticated;
        if (authorized) {
            clearInterval(tryLoginInterval.current);
            tryLoginInterval.current = null;
        }
        if (connected && !showChildren) {
            setShowChildren(true);
        }
        if (authorized && !connected) {
            if (canReconnect) {
                connectEventbus();
            } else {
                authenticateUser();
            }
        }
    }, [state, showChildren]);

    const initPendo = (userData: AppUser, keycloak: Keycloak) => {
        if (!pendoInitialized.current) {
            // @ts-ignore
            if (!window.pendo) {
                console.warn("pendo not available!");
                return;
            }
            const whatfixUserID = `${getGlobalStates().systemID}:${userData.userName}`;
            window.sessionStorage.setItem("whatfixUserID", whatfixUserID);
            window.sessionStorage.setItem("roles", getUserRoles(keycloak).join(","));
            // @ts-ignore
            window.pendo.initialize({
                visitor: {
                    id: whatfixUserID, // Required if user is logged in
                    // email:        // Recommended if using Pendo Feedback, or NPS Email
                    // full_name:    // Recommended if using Pendo Feedback
                    role: getUserRoles(keycloak).join(","),
                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },

                account: {
                    id: getGlobalStates().systemID, // Highly recommended, required if using Pendo Feedback
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional
                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                },
            });
            pendoInitialized.current = true;
            console.info("pendo initialized");
        }
    };

    const authenticateUser = () => {
        if (getGlobalStates().keycloak && getGlobalStates().keycloak.authenticated) {
            authorizeUser(getGlobalStates().keycloak);
        } else if (authenticate) {
            console.debug("authentication required");
            login();
            tryLoginAtInterval();
        }
    };

    const isUserAuthorized = () => {
        const { keycloak, userData } = state;

        if (keycloak === null || !keycloak.authenticated || userData === null) {
            return false;
        }

        if (userData.userID === null || userData.userID.value !== keycloak.tokenParsed.sub || userData.userSessionID === null || userData.userSessionID !== keycloak.tokenParsed.sid) {
            return false;
        }

        return true;
    };

    const authorizeUser = (keycloak: Keycloak) => {
        const keycloakUser = {
            userID: keycloak.tokenParsed.sub,
            userName: keycloak.tokenParsed.preferred_username,
            userSessionID: keycloak.tokenParsed.sid,
        } as KeycloakUser;
        authUser(keycloak.token, keycloakUser)
            .then((response) => {
                if (response.status >= 400) {
                    console.log("Error authorizing user: " + response.status);
                    handleUserNotAuthorized();
                } else if (!response.data) {
                    console.log("Error authorizing user: empty response");
                    handleUserNotAuthorized();
                } else if (response.data.severity !== "SUCCESS") {
                    console.log(`authorization failure`, response.data.unlocalizedMsg);
                    handleUserNotAuthorized();
                } else {
                    const userData = response.data.arg as AppUser;
                    console.debug(userData);
                    handleUserAuthorized(userData, keycloak);
                }
            })
            .catch((e) => {
                console.error("Error authorizing user ", e);
                handleUserNotAuthorized();
            });
    };

    const handleUserAuthorized = (userData: AppUser, keycloak: Keycloak) => {
        clearInterval(tryLoginInterval.current);
        tryLoginInterval.current = null;
        console.debug(`Authorized user = ${userData.userName}`);
        const permissions = gcmUserPermissions(keycloak);
        console.debug(permissions);
        initPendo(userData, keycloak);
        setGcmGlobalStateData(userData, permissions, keycloak);
        setState({
            keycloak: keycloak,
            canReconnect: true,
            connected: false,
            userData: userData,
            actions: [],
            clearanceData: [],
            gcmUserPermission: permissions,
        });
    };

    const handleUserNotAuthorized = () => {
        setLoggingOut(false);
        reSetGcmGlobalStateData();
        setState({
            keycloak: null,
            canReconnect: true,
            connected: false,
            userData: null,
            gcmUserPermission: {},
        });

        clearInterval(tryLoginInterval.current);
        tryLoginInterval.current = null;
        setTimeout(() => authenticateUser(), 5000);
    };

    const connectEventbus = () => {
        console.debug("connectEventbus - user = ", getGlobalStates().userData.userName);
        const eventbus = new ChronosEventBus2(getGlobalStates().systemID, getGlobalStates().userData.userID.value, getGlobalStates().userData.userSessionID);
        eventbus
            .get()
            .then((ev) => {
                setEventBus(ev);
                setState({
                    eventbus: ev,
                    connected: true,
                });
                ev.connected = true;
                ev.sendMsg(REQUEST_POST_LOGIN, {
                    clientAppName: getGlobalStates().appID.value,
                });
                getPostLoginDataTask();

                ev.onClose = (e) => {
                    ev.connected = false;
                    setTimeout(() => {
                        if (!state.keycloak || !state.keycloak.authenticated) {
                            handleUserNotAuthorized();
                        } else {
                            setState({ connected: false, canReconnect: e && e.wasClean });
                        }
                    }, 500);
                };
            })
            .catch((e) => {
                console.error("Error connecting eventbus", e);
            });
    };

    const handleKeycloakInit = (keycloak: Keycloak) => {
        if (keycloak.authenticated) {
            console.debug(`Authenticated user = ${keycloak.tokenParsed.preferred_username}`);
            authorizeUser(keycloak);
            refreshToken(keycloak);
        } else {
            handleUserNotAuthorized();
        }
    };

    const refreshToken = (keycloak: Keycloak) => {
        const refreshInterval = 30 * 1000; // Refresh every 30 seconds

        setInterval(() => {
            if (keycloak.authenticated) {
                keycloak.updateToken(60) // Refresh if token expires in 60 seconds
                    .then(() => {
                        console.log('Token refreshed successfully');
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                console.error("Keycloak is not authenticated. Token refresh skipped.");
            }
        }, refreshInterval);
    };

    const initKeycloak = (config) => {
        console.warn("initKeycloak", config);
        const redirectUri = redirectUriFor(getGlobalStates().appID.value);
        console.warn(redirectUri);
        const keycloakConfig = {
            url: config.kc.url,
            realm: config.kc.realm,
            clientId: config.kc.clientID,
        } as Keycloak.KeycloakConfig;
        const keycloak = new Keycloak(keycloakConfig);

        keycloak.onAuthSuccess = () => {
            console.debug("onAuthSuccess");
        };
        keycloak.onAuthRefreshSuccess = () => {
            console.info("onAuthRefreshSuccess");
        };
        keycloak.onAuthRefreshError = () => {
            console.info("onAuthRefreshError");
        };
        keycloak
            .init({
                onLoad: "login-required",
                enableLogging: true,
                checkLoginIframe: true,
                checkLoginIframeInterval: 5,
                flow: "hybrid",
            })
            .then(() => {
                handleKeycloakInit(keycloak);
            })
            .catch((e) => {
                console.error("Error initializing keycloak", e);
            });
    };

    const handleReceiveInitStatus = (response: AxiosResponse) => {
        console.warn(response);
        if (response.status >= 400) {
            console.log("Error getting service-info: " + response.status);
        } else if (!response.data) {
            console.log("Error getting service-info: empty response");
        } else if (response.data.severity !== "SUCCESS") {
            console.log(`handleReceiveInitStatus failure`, response.data.unlocalizedMsg);
        } else {
            const initStatus = response.data.arg;

            console.debug(JSON.stringify(initStatus));

            initKeycloak(initStatus);
        }
    };

    const login = () => {
        console.debug(`login`);
        if (!getGlobalStates().isReady) {
            console.log("GlobalStates not initialized: ", getGlobalStates());
        } else {
            processLogin();
        }
    };

    const processLogin = () => {
        console.debug(`processLogin`);
        requestInitStatus()
            .then(handleReceiveInitStatus)
            .catch((e) => {
                console.error("Cannot login. Error retrieving service info", e);
            });
    };

    const processKeycloakLogout = () => {
        console.debug("Logout from keycloak");
        state.keycloak
            .logout({
                redirectUri: window.location.href,
            })
            .then((success) => {
                handleUserNotAuthorized();
                console.debug("User successfullul un-authenticated");
            })
            .catch((e) => {
                handleUserNotAuthorized();
                console.warn("Error un-authenticating the user", e);
            });
    };

    const processAetherLogout = () => {
        processKeycloakLogout();
    };

    const logout = () => {
        if (!state.keycloak || !state.keycloak.authenticated) {
            handleUserNotAuthorized();
        } else {
            if (isUserAuthorized()) {
                processAetherLogout();
            } else {
                processKeycloakLogout();
            }
        }
    };

    const sendMsg = (msgDef: MessageDefinition, msg?: Object | string | number | boolean) => { };

    const value = useMemo(
        () => ({
            state,
            setState,
            login,
            logout,
            sendMsg,
        }),
        [state, setState]
    );

    return (
        <AuthenticationContext.Provider value={value}>
            <>{showChildren ? children : <InitLoading />}</>
        </AuthenticationContext.Provider>
    );
}
