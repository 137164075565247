"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZEROCLIENTS_DATA = exports.UNSET_DISPLAY = exports.UCLIENT_FIRMWARE_CONFIG = exports.UCLIENT_APP_VERSION = exports.UCLIENTS_DATA = exports.ONBOARDS_DATA = void 0;
var _common = require("../../../models/common");
var _uclientGiada = require("../../../models/uclient/uclientGiada");
var _common2 = require("./common");
var UNSET_DISPLAY = exports.UNSET_DISPLAY = {
  id: _common.STRINGID_NOT_SET,
  name: "Not Available",
  description: "Not Available",
  giadaGrid: _uclientGiada.GIADA_NOT_SET,
  ownedBy: {
    value: _common2.STANDALONE_SYSTEMID
  },
  activeOn: {
    value: _common2.STANDALONE_SYSTEMID
  },
  ownedBySystem: {
    value: _common2.STANDALONE_SYSTEMID
  },
  activeOnSystem: {
    value: _common2.STANDALONE_SYSTEMID
  },
  stationLabel: {
    value: "Not Available"
  },
  lockedTo: {
    value: _common2.STANDALONE_SYSTEMID
  },
  lockStatus: "LOCKED",
  currentResolution: {
    width: 1920,
    height: 1080
  },
  rotation: {
    radians: 0
  },
  audioSettings: {
    audioOffset: {
      offset: 0
    },
    audioVolume: {
      level: 0,
      muted: true
    }
  },
  colourCalibrationData: {
    red: 10000,
    green: 10000,
    blue: 10000,
    brightness: 32768,
    contrast: 32768
  },
  physicalData: {
    area: {
      width: {
        lengthMM: 885.5207457185421,
        unit: "IN"
      },
      height: {
        lengthMM: 498.10541946667996,
        unit: "IN"
      }
    }
  },
  stationIDVisibility: "INVISIBLE",
  latency: {
    millis: 250
  },
  lockTaskMode: true,
  managedBy: {
    value: _common2.STANDALONE_SYSTEMID
  },
  timeZone: {
    value: Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  provisioningType: 'MAPPING',
  transientData: {
    id: _common.STRINGID_NOT_SET,
    name: "Not Available",
    location: "Not Available",
    origin: {
      value: _common2.STANDALONE_SYSTEMID
    },
    hardwareData: {
      modelName: "rk3399-all",
      serialNumber: "00E04C023DA8",
      manufacturer: "rockchip",
      firmwareVersion: "WB-BOX-WT399TR110-UF001-K-210708",
      platformName: "Android 7.1.2",
      appVersion: {
        version: "3.1.0.19"
      }
    },
    networkData: {
      addressInfo: {
        dhcpEnabled: true,
        ipAddress: {
          value: "192.168.134.200"
        },
        mask: {
          value: "255.255.255.0"
        }
      },
      gatewayAddress: {
        value: ""
      },
      macAddress: {
        value: "00:e0:4c:02:3d:a8"
      }
    },
    systemInfo: {
      systemID: {
        value: _common2.STANDALONE_SYSTEMID
      },
      previousSystemID: {
        value: "NOT_SET"
      },
      hostName: "userful-55ea9c"
    },
    connectionStatus: {
      connected: true,
      wiredConnection: true,
      hasNTP: true,
      connectingToHost: true,
      connectionStatus: "CONNECTED"
    },
    pairingCode: {
      value: ""
    },
    deviceResolution: {
      width: 1920,
      height: 1080
    },
    displayData: {
      id: _common.STRINGID_NOT_SET,
      displayID: {
        value: ":0"
      },
      displayResolutionData: {
        effective: {
          width: -1,
          height: -1
        },
        "native": {
          width: 1920,
          height: 1080
        },
        current: {
          width: 1920,
          height: 1080
        },
        availableResolutions: [{
          width: 1920,
          height: 1080
        }]
      },
      displayColourData: {
        colourDepth: {
          colourDepth: 24
        },
        availableColourDepths: [{
          colourDepth: 24
        }],
        currentColourCalibrationData: {
          red: 10000,
          green: 10000,
          blue: 10000,
          brightness: 32768,
          contrast: 32768
        }
      },
      displayMonitorData: {
        monitorDiagonal: {
          lengthMM: 0,
          unit: "NONE"
        },
        monitorTypeString: " "
      }
    }
  }
};
var UCLIENTS_DATA = exports.UCLIENTS_DATA = [];
var ZEROCLIENTS_DATA = exports.ZEROCLIENTS_DATA = [];
var ONBOARDS_DATA = exports.ONBOARDS_DATA = [];
var UCLIENT_APP_VERSION = exports.UCLIENT_APP_VERSION = "3.1.0.38";
var UCLIENT_FIRMWARE_CONFIG = exports.UCLIENT_FIRMWARE_CONFIG = {
  firmwareDownloadUrl: "http://uclient.ws/fw/",
  webOSFirmwareMapping: {
    SM5KE: "04.08.81",
    SVM5F: "03.09.70",
    UH5F: "03.21.40",
    UH5E: "04.08.81",
    SH7E: "04.08.81",
    UH7F: "03.21.40",
    VH7E: "03.09.70",
    SM5J: "06.05.91",
    UM3E: "04.08.81",
    SVH7F: "03.09.70",
    VM5E: "03.09.70",
    WP400: "04.08.81",
    UH5PE: "04.08.81"
  },
  androidFirmwareMapping: {
    Userful: "Userful-FW-WTR39T1R200-TE3399-E-230519"
  },
  certifiedModels: ["SM5KE", "SH7E", "UH5E", "UM3E", "UH5PE", "WP400", "UH5F", "UH7F", "VH7E", "VM5E", "SVM5F", "SVH7F", "SM5J"]
};