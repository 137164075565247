import { useEffect, useState } from "react";
import UserfulTable from "../../../common/components/UserfulTable/UserfulTable";
import { convertBackendHardwareInfoToTable, convertBIOSInfoToTable, convertCaptureCardToTable, convertChassisInfoToTable, convertGPUToTable, convertMemoryInfoToTable, convertProcessorInfoToTable } from "../../../common/components/UserfulTable/UserfulTableConverter";

import { ITable } from "../../../common/components/UserfulTable/userfulTableInterface";
import { formatTimestamp, formatElapsedTime } from "../../../common/functions/formatters";
import { ClusterDeviceInfo, IClusterInfo, IConstellationInfo } from "../../../model/CarbonExternalModels";
import "./DeviceSystemInfo.scss"

interface IProps {
    foundObject: { cluster: IClusterInfo; constellation: IConstellationInfo, device: ClusterDeviceInfo };
}

export default function DeviceSystemInfo({ foundObject }: IProps) {

    const [currentTime, setCurrentTime] = useState(Date.now());

    const summaryTable: ITable = convertBackendHardwareInfoToTable(foundObject.device.hardwareInfo);
    const gpuTable: ITable = convertGPUToTable(foundObject.device.gpuInfo);
    const memoryInfoTable: ITable = convertMemoryInfoToTable(foundObject.device.hardwareInfo);
    const processorInfoTable: ITable = convertProcessorInfoToTable(foundObject.device.hardwareInfo);
    const captureCardTable: ITable = convertCaptureCardToTable(foundObject.device.captureCardInfo);
    const chassisInfoTable: ITable = convertChassisInfoToTable(foundObject.device.hardwareInfo);
    const biosInfoTable: ITable = convertBIOSInfoToTable(foundObject.device.hardwareInfo);

    // console.warn(foundObject.device.bootupTime);
    const prettyTimestamp = formatTimestamp(foundObject.device.bootupTime);
    const prettySince = formatElapsedTime(foundObject.device.bootupTime, currentTime);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(timer);
    }, []);


    return (
        <div className="device-system-info-container">
            <div className="device-system-info-container-header">
                <div className="info-container">
                    <div className="info-container-image">
                        <img src="./images/device.jpeg" alt="device" />
                    </div>
                    <div className="info-container-text">
                        <div className="info-container-text-header">{foundObject.device.name}</div>
                        <div className="info-container-text-description">Server Uptime: {prettySince}</div>
                        <div className="info-container-text-description">Last Server Reboot: {prettyTimestamp}</div>
                    </div>
                </div>
            </div>
            <div className="device-system-info-container-body">
                <UserfulTable table={summaryTable} nonInteractive />
                <UserfulTable table={gpuTable} nonInteractive />
                <UserfulTable table={memoryInfoTable} nonInteractive />
                <UserfulTable table={processorInfoTable} nonInteractive />
                <UserfulTable table={captureCardTable} nonInteractive />
                <UserfulTable table={chassisInfoTable} nonInteractive />
                <UserfulTable table={biosInfoTable} nonInteractive />
            </div>
        </div>
    );
}
