"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sanitizeMixerMapper = exports.sanitizeEdgeMapper = exports.SNAP_SHOTS = exports.PASSIVE_MAPPERS = exports.MIXER_MAPPERS = exports.MAPPER_COLLECTIONS = exports.EDGE_MAPPERS = exports.DEFAULT_STREAM_COMP = exports.DEFAULT_MIXER_MAPPER = exports.DEFAULT_INSET_VIDEO_DATA = exports.DEFAULT_INSET_PLAYLIST_ITEM = exports.DEFAULT_INSET_COMP = exports.DEFAULT_INSET_AUDIO_DATA = exports.CONTROL_ROOM = void 0;
var _common = require("../../../models/common");
var _display = require("../../../models/display");
var _MappingGroups = require("../../../models/mapping/MappingGroups");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var MIXER_MAPPERS = exports.MIXER_MAPPERS = [];
var EDGE_MAPPERS = exports.EDGE_MAPPERS = [];
var CONTROL_ROOM = exports.CONTROL_ROOM = [];
var SNAP_SHOTS = exports.SNAP_SHOTS = [];
var PASSIVE_MAPPERS = exports.PASSIVE_MAPPERS = [];
var MAPPER_COLLECTIONS = exports.MAPPER_COLLECTIONS = [];

// for backward compatibility with old saved standalone_data.json
var sanitizeMixerMapper = exports.sanitizeMixerMapper = function sanitizeMixerMapper(data) {
  return _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_MIXER_MAPPER), data), {}, {
    insets: data.insets.map(function (i) {
      return _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_INSET_COMP), i), {}, {
        playList: _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_INSET_COMP.playList), i.playList), {}, {
          items: i.playList.items.map(function (ip) {
            return _objectSpread(_objectSpread({}, DEFAULT_INSET_PLAYLIST_ITEM), ip);
          })
        }),
        videoData: i.videoData.map(function (iv) {
          return _objectSpread(_objectSpread({}, DEFAULT_INSET_VIDEO_DATA), iv);
        }),
        audioData: i.audioData.map(function (ia) {
          return _objectSpread(_objectSpread({}, DEFAULT_INSET_AUDIO_DATA), ia);
        })
      });
    }),
    streams: data.streams.map(function (s) {
      return _objectSpread(_objectSpread({}, DEFAULT_STREAM_COMP), s);
    })
  });
};
var sanitizeEdgeMapper = exports.sanitizeEdgeMapper = function sanitizeEdgeMapper(data) {
  var result = _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_MIXER_MAPPER), data), {}, {
    inset: data.inset ? _objectSpread(_objectSpread({}, DEFAULT_INSET_COMP), data.inset) : _objectSpread({}, DEFAULT_INSET_COMP)
  });
  delete result.insets;
  return result;
};
var DEFAULT_INSET_AUDIO_DATA = exports.DEFAULT_INSET_AUDIO_DATA = {
  id: _common.STRINGID_NOT_SET,
  mapperID: _common.STRINGID_NOT_SET,
  audioMixerID: _common.STRINGID_NOT_SET,
  sceneID: _common.STRINGID_NOT_SET,
  visibility: "VISIBLE",
  audioSettings: {
    audioVolume: {
      level: 100,
      muted: false
    },
    audioOffset: {
      millis: 0
    }
  }
};
var DEFAULT_INSET_VIDEO_DATA = exports.DEFAULT_INSET_VIDEO_DATA = {
  id: _common.STRINGID_NOT_SET,
  mapperID: _common.STRINGID_NOT_SET,
  videoMixerID: _common.STRINGID_NOT_SET,
  sceneID: _common.STRINGID_NOT_SET,
  position: _common.POSITION_FULL,
  visibility: "VISIBLE",
  textOverlay: {
    text: "",
    visibility: "INVISIBLE",
    config: {
      colour: "ORANGE",
      font: {
        fontName: "Sans"
      },
      style: {
        styleName: "Bold"
      },
      textSize: 32,
      shadow: true,
      outline: true,
      outlineColour: "BLACK",
      xpos: 0.05,
      ypos: 0.05
    }
  },
  showSourceName: "INVISIBLE",
  movable: "ACTIVE"
};
var DEFAULT_INSET_PLAYLIST_ITEM = exports.DEFAULT_INSET_PLAYLIST_ITEM = {
  id: _common.STRINGID_NOT_SET,
  name: "Default",
  description: "",
  createdFrom: _common.STRINGID_NOT_SET,
  behaviour: {
    activationDuration: {
      millis: 0
    },
    preStartDuration: {
      millis: 0
    },
    playDuration: {
      millis: 0
    },
    repeatConfig: {
      duration: {
        millis: 0
      },
      times: {
        times: -1
      }
    },
    persistenceType: "KEEP_RUNNING"
  },
  source: {
    id: _common.STRINGID_NOT_SET,
    name: "Default",
    description: "",
    sceneID: _common.STRINGID_NOT_SET,
    createdFrom: _common.STRINGID_NOT_SET,
    type: {
      value: "Sapphire_Spaces_-_Beta",
      name: "Sapphire_Spaces_-_Beta"
    },
    params: [],
    displayResolution: _display.DEFAULT_RESOLUTION,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE",
    seatID: _common.STRINGID_NOT_SET,
    scaling: "FULL"
  }
};
var DEFAULT_STREAM_COMP = exports.DEFAULT_STREAM_COMP = {
  id: _common.STRINGID_NOT_SET,
  name: "LiveUpdates-6296bcd4-fffd-4814-b588-9ecbf8b515f3",
  description: "",
  sceneID: _common.STRINGID_NOT_SET,
  deploymentInfo: {
    audioMixerID: _common.STRINGID_NOT_SET,
    videoMixerID: _common.STRINGID_NOT_SET
  },
  resolution: {
    width: 864,
    height: 480
  },
  audioSettings: {
    audioVolume: {
      level: 0,
      muted: true
    },
    audioOffset: {
      millis: 0
    }
  },
  positionInfo: _common.POSITION_FULL,
  streamData: {
    id: _common.STRINGID_NOT_SET,
    type: "MJPEG",
    url: "2D23F788-FA16-4E67-BC85-E89D9552CE2C"
  }
};
var DEFAULT_INSET_COMP = exports.DEFAULT_INSET_COMP = {
  id: _common.STRINGID_NOT_SET,
  name: "Default",
  description: "Default",
  playList: {
    id: _common.STRINGID_NOT_SET,
    name: "Default",
    description: "Default",
    sceneID: _common.STRINGID_NOT_SET,
    createdFrom: _common.STRINGID_NOT_SET,
    behaviour: {
      playTimes: {
        times: -1
      },
      startBehaviour: "AUTO_START"
    },
    items: [_objectSpread({}, DEFAULT_INSET_PLAYLIST_ITEM)]
  },
  audioData: [_objectSpread({}, DEFAULT_INSET_AUDIO_DATA)],
  videoData: [_objectSpread({}, DEFAULT_INSET_VIDEO_DATA)],
  sceneID: _common.STRINGID_NOT_SET
};
var DEFAULT_MIXER_MAPPER = exports.DEFAULT_MIXER_MAPPER = {
  id: _common.STRINGID_NOT_SET,
  name: "",
  description: "",
  sceneID: _common.STRINGID_NOT_SET,
  appInstanceID: {
    value: "NOT_SET",
    appID: _common.STRINGID_NOT_SET
  },
  gpuID: {
    videoEngineID: _common.STRINGID_NOT_SET,
    gpuSlotID: _common.STRINGID_NOT_SET
  },
  canvasSize: _display.DEFAULT_RESOLUTION,
  logicalGridData: _MappingGroups.DEFAULT_LOGICAL_GRID_DATA,
  insets: [],
  destinations: [],
  groups: [{
    id: {
      value: "NOT_SET"
    },
    destinations: []
  }],
  streams: [],
  textOverlay: {
    text: "",
    visibility: "INVISIBLE",
    config: {
      colour: "ORANGE",
      font: {
        fontName: "Sans"
      },
      style: {
        styleName: "Bold"
      },
      textSize: 32,
      shadow: true,
      outline: true,
      outlineColour: "BLACK",
      xpos: 0.05,
      ypos: 0.05
    }
  },
  videoQuality: {
    quality: 80
  },
  uclientLatency: 0.2,
  frameRate: {
    frames: 30,
    seconds: 1
  },
  provisioningStrategy: "KEEP_RUNNING",
  origin: {
    id: _common.STRINGID_NOT_SET,
    type: "NONE",
    originApp: {
      value: "NOT_SET",
      appID: _common.STRINGID_NOT_SET
    }
  },
  mixerPlayerActivationStatus: "ACTIVE",
  createdTime: 1727197846.629737,
  updatedTime: 1727197846.728602,
  userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
};