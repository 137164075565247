"use strict";

var _cdm = require("../../functions/cdm");
var _videoWalls = require("../../functions/videoWalls");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _calibrationMsgDefinitions = require("../../../message/messageTypeDefinitions/calibration/calibrationMsgDefinitions");
var _CDMMsgDefinations = require("../../../message/messageTypeDefinitions/cdm/CDMMsgDefinations");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_calibrationMsgDefinitions.REQUEST_START_CALIBRATION_SESSION, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_calibrationMsgDefinitions.RESPONSE_START_CALIBRATION_SESSION, {
    id: {
      value: "calibration-session-id"
    }
  }, undefined, 1000);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_calibrationMsgDefinitions.REQUEST_END_CALIBRATION_SESSION, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_calibrationMsgDefinitions.RESPONSE_END_CALIBRATION_SESSION, {
    id: {
      value: "calibration-session-id"
    }
  });
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_calibrationMsgDefinitions.REQUEST_UPDATE_CALIBRATION_GEOMETRY, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_calibrationMsgDefinitions.RESPONSE_UPDATE_CALIBRATION_GEOMETRY, null);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_calibrationMsgDefinitions.REQUEST_VIDEO_WALL_REMOVE, function (payload) {
  var videoWall = (0, _videoWalls.removeVideoWall)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_CDMMsgDefinations.NOTIFY_VIDEOWALL_DEST_REMOVED, videoWall, undefined, 500);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_calibrationMsgDefinitions.NOTIFY_VIDEO_WALL_REMOVED, payload, undefined, 500);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_calibrationMsgDefinitions.REQUEST_END_CALIBRATION_SESSION_AND_CREATE, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_calibrationMsgDefinitions.RESPONSE_END_CALIBRATION_SESSION, {
    id: {
      value: "calibration-session-id"
    }
  });
  var videoWall = payload.videowall;
  (0, _videoWalls.addVideoWalls)(videoWall);
  var destinations = (0, _cdm.getStandaloneVideoWallDestinations)();
  var foundVWDest = destinations.find(function (item) {
    return item.endPoint.id.videoWallID.value === videoWall.id.value;
  });
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_CDMMsgDefinations.NOTIFY_VIDEOWALL_DEST_ADDED, foundVWDest, undefined, 500);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_calibrationMsgDefinitions.NOTIFY_VIDEO_WALL_CREATED, videoWall, undefined, 500);
});

// registerStandaloneMsgHandler(REQUEST_VIDEO_WALL_CREATE, (payload) => {
//     addVideoWalls(payload);
//     const destinations = getStandaloneVideoWallDestinations();
//     const foundVWDest = destinations.find(item => item.endPoint.id.videoWallID.value === payload.id.value);
//     respondStandaloneRequest(NOTIFY_VIDEOWALL_DEST_ADDED, foundVWDest, undefined, 500);
//     respondStandaloneRequest(NOTIFY_VIDEO_WALL_CREATED, payload, undefined, 500);
// });

(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_calibrationMsgDefinitions.REQUEST_END_CALIBRATION_SESSION_AND_UPDATE, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_calibrationMsgDefinitions.RESPONSE_END_CALIBRATION_SESSION, {
    id: {
      value: "calibration-session-id"
    }
  });
  var videoWall = payload.videowall;
  (0, _videoWalls.updateVideoWalls)(videoWall);
  var destinations = (0, _cdm.getStandaloneVideoWallDestinations)();
  var foundVWDest = destinations.find(function (item) {
    return item.endPoint.id.videoWallID.value === videoWall.id.value;
  });
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_CDMMsgDefinations.NOTIFY_VIDEOWALL_DEST_UPDATED, foundVWDest, undefined, 500);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_calibrationMsgDefinitions.NOTIFY_VIDEO_WALL_UPDATED, videoWall, undefined, 500);
});

// registerStandaloneMsgHandler(REQUEST_VIDEO_WALL_UPDATE, (payload) => {
//     updateVideoWalls(payload);
//     const destinations = getStandaloneVideoWallDestinations();
//     const foundVWDest = destinations.find(item => item.endPoint.id.videoWallID.value === payload.id.value);
//     respondStandaloneRequest(NOTIFY_VIDEOWALL_DEST_UPDATED, foundVWDest, undefined, 500);
//     respondStandaloneRequest(NOTIFY_VIDEO_WALL_UPDATED, payload, undefined, 500);
// });