"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setProxyData = exports.getTimeData = exports.getProxyData = exports.getNetworkData = exports.getHostnameData = void 0;
var _store = require("../data/store");
var _utils = require("./utils");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var getTimeData = exports.getTimeData = function getTimeData() {
  return _objectSpread(_objectSpread({}, (0, _store.getStandaloneDataStore)().timeData), {}, {
    id: {
      value: (0, _store.getStandaloneDataStore)().systemID
    },
    systemTimeData: _objectSpread(_objectSpread({}, (0, _store.getStandaloneDataStore)().timeData.systemTimeData), {}, {
      currentSystemTime: {
        millis: new Date().getTime()
      },
      currentTimeZone: {
        value: Intl.DateTimeFormat().resolvedOptions().timeZone,
        offset: {
          millis: new Date().getTimezoneOffset() * 60 * 60 * 1000
        }
      }
    })
  });
};
var getNetworkData = exports.getNetworkData = function getNetworkData() {
  return _objectSpread(_objectSpread({}, (0, _store.getStandaloneDataStore)().networkData), {}, {
    id: {
      value: (0, _store.getStandaloneDataStore)().systemID
    },
    networkInterfaces: (0, _store.getStandaloneDataStore)().networkData.networkInterfaces.map(function (n) {
      return _objectSpread(_objectSpread({}, n), {}, {
        id: {
          value: (0, _store.getStandaloneDataStore)().systemID
        }
      });
    })
  });
};
var getHostnameData = exports.getHostnameData = function getHostnameData() {
  var _getStandaloneDataSto;
  return {
    id: {
      value: (0, _store.getStandaloneDataStore)().systemID
    },
    hostname: ((_getStandaloneDataSto = (0, _store.getStandaloneDataStore)().videoEngines[0]) === null || _getStandaloneDataSto === void 0 ? void 0 : _getStandaloneDataSto.name) || "Demo System"
  };
};
var getProxyData = exports.getProxyData = function getProxyData() {
  return {
    id: {
      value: (0, _store.getStandaloneDataStore)().systemID
    },
    settings: (0, _store.getStandaloneDataStore)().proxyData.settings
  };
};
var setProxyData = exports.setProxyData = function setProxyData(request) {
  return (0, _utils.updateStandaloneDataObject)(request, "proxyData");
};