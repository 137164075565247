"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStandaloneDataObject = exports.updateStandaloneData = exports.removeStandaloneData = exports.getStandaloneData = exports.createStandaloneData = void 0;
var _store = require("../data/store");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneData = exports.getStandaloneData = function getStandaloneData(key) {
  var data = (0, _store.getStandaloneDataStore)()[key];
  if (Array.isArray(data)) {
    return _toConsumableArray(data);
  }
  if (_typeof(data) === 'object') {
    return _objectSpread({}, data);
  }
  return data;
};
var createStandaloneData = exports.createStandaloneData = function createStandaloneData(update, key) {
  var existing = getStandaloneData(key);
  var data = _objectSpread({}, update);
  existing.push(data);
  (0, _store.updateStandaloneDataStore)(_defineProperty({}, key, existing));
  return data;
};
var updateStandaloneData = exports.updateStandaloneData = function updateStandaloneData(update, key) {
  var existing = getStandaloneData(key);
  var foundIndex = existing.findIndex(function (item) {
    return item.id.value === update.id.value;
  });
  if (foundIndex >= 0) {
    var data = _objectSpread(_objectSpread({}, existing[foundIndex]), update);
    existing.splice(foundIndex, 1, data);
    (0, _store.updateStandaloneDataStore)(_defineProperty({}, key, existing));
    return data;
  }
  return null;
};
var updateStandaloneDataObject = exports.updateStandaloneDataObject = function updateStandaloneDataObject(update, key) {
  var existing = getStandaloneData(key);
  if (existing) {
    var data = _objectSpread(_objectSpread({}, existing), update);
    (0, _store.updateStandaloneDataStore)(_defineProperty({}, key, existing));
    return data;
  }
  return null;
};
var removeStandaloneData = exports.removeStandaloneData = function removeStandaloneData(id, key) {
  var existing = getStandaloneData(key);
  var foundIndex = existing.findIndex(function (item) {
    return item.id.value === id.value;
  });
  if (foundIndex >= 0) {
    var result = existing.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)(_defineProperty({}, key, existing));
    return result;
  }
  return null;
};