import { faEllipsisVertical, faPenToSquare, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { KeycloakGroup } from "../../../model/CarbonExternalModels";
import { useCarbonDispatch } from "../../../store/carbonStore";
import UserGroupDelete from "../userGroupDelete/UserGroupDelete";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { Button } from "userful-chronos-common-ui/dist";
import { AppSliceActions } from "../../../store/app/appSlice";

interface IProps {
    group: KeycloakGroup;
}

export default function UserGroupActions(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    if (!props.group.editable || !permissions.isUserSuppressor) return null;

    return (
        <Dropdown
            className="userfulTableActions"
            onClick={(e) => {
                console.warn("UserGroupActions");

                e.stopPropagation();
            }}
        >
            <Dropdown.Toggle bsPrefix="userfulTableActionsToggle" as={Button}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="dropdownMenuSwitch"
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
            >
                 <Dropdown.Item
                    key={props.group.id.value}
                    className="dropdownItem"
                    onClick={() => {
                        dispatch(
                            AppSliceActions.setAppView({
                                type: "USER_GROUP",
                                id: props.group.id,
                            })
                        );
                    }}
                >
                    <div className="icon-container">
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </div>
                    <div className="textInside">Edit</div>
                </Dropdown.Item>
                <UserGroupDelete group={props.group} type="DROPDOWN" permissions={permissions} />
            </Dropdown.Menu>
        </Dropdown>
    );
}
