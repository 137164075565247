import React from "react";
import { selectConstellationFilters, selectFiltersVisibility, selectSearchInput, useCarbonDispatch, useCarbonSelector } from "../../store/carbonStore";
import EmptyPlaceholder from "../../common/components/placeholders/EmptyPlaceholder";
import { UserfulSearchBar, UserfulTooltip } from "userful-chronos-common-ui/dist";
import { FiltersActions } from "../../store/filters/filtersSlice";

import ConstellationCard from "./constellation/constellationCard/ConstellationCard";
import CreateConstellation from "./constellation/CreateConstellation";
import { IConstellationInfo } from "../../model/CarbonExternalModels";
import { filterConstellations } from "../../store/filters/filtersManager";
import { AppSliceActions } from "../../store/app/appSlice";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { apiGetConstellations } from "../../messages/api/gcmAPICallers";

interface IProps {
    constellations: { [id: string]: IConstellationInfo };
}

export default function ConstellationsTable(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    // Visibility
    const filtersVisibility = useCarbonSelector(selectFiltersVisibility);

    // Filtering
    const constellationFilters = useCarbonSelector(selectConstellationFilters);
    const searchInput = useCarbonSelector(selectSearchInput);

    // Data
    const allConstellations: IConstellationInfo[] = [];
    for (const property in props.constellations) {
        allConstellations.push(props.constellations[property]);
    }
    const filteredConstellations = filterConstellations(constellationFilters, searchInput.constellations, allConstellations);

    // Functions
    const onSearchBarChange = function (input: string) {
        dispatch(FiltersActions.setSearchInput({ ...searchInput, constellations: input }));
    };

    // Renders
    const insertBody = () => {
        if (allConstellations.length > 0) {
            if (filteredConstellations.length > 0) {
                return (
                    <div className="constellationCardsWrapper">
                        {filteredConstellations.map((s) => {
                            return <ConstellationCard key={s.definition.id.value} constellation={s} />;
                        })}
                    </div>
                );
            } else {
                return <EmptyPlaceholder img="./images/notFound.png" title={"No Constellations Found"} description={"Please refine your search/filter criteria and try again."} />;
            }
        } else {
            return (
                <EmptyPlaceholder
                    img="./images/notFound.png"
                    title={"No Constellations Available"}
                    description={"There are no available constellations, click Create Constellation button."}
                    additionalComponents={<CreateConstellation type={"SECONDARY"} permissions={permissions} />}
                />
            );
        }
    };

    // console.warn(permissions);
    if (!permissions.isConstellationViewer) return null;

    return (
        <div className="tableWrapper">
            <div className="tableHeader">
                <div className="horizontalWrapper">
                    <div className="cluster normalGap">
                        {/* <button className="iconButton">
                                    <FontAwesomeIcon icon={faBarsFilter} />
                                </button> */}
                        <UserfulSearchBar forceValue={searchInput.constellations} onChange={onSearchBarChange} />
                        {/* <UserfulTooltip placement="bottom" title="Refresh Data">
                            <button
                                className="iconButton"
                                onClick={(e) => {
                                    apiGetConstellations(getGlobalStates().keycloak.token);
                                }}
                            >
                                <FontAwesomeIcon icon={faRefresh} />
                            </button>
                        </UserfulTooltip> */}
                    </div>
                    <div className="cluster normalGap">
                        <CreateConstellation type={"PRIMARY"} permissions={permissions} />
                    </div>
                </div>
                {filtersVisibility.constellations && <div className="horizontalWrapper"></div>}
            </div>

            <div className="tableBodyWrapper">
                <div className="tableSubHeader">
                    <div className="tableSubHeaderGroup">
                        <div
                            className="currentTextSelected"
                            onClick={(e) => {
                                dispatch(
                                    AppSliceActions.setAppView({
                                        type: "CONSTELLATIONS",
                                        id: { value: "NOT_SET" },
                                    })
                                );
                            }}
                        >
                            Index
                        </div>
                    </div>
                </div>
                {insertBody()}
            </div>
        </div>
    );
}
