"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_UPDATE_PASSIVE_COLLECTION_HEADER = exports.RESPONSE_UPDATE_PASSIVE_COLLECTION = exports.RESPONSE_STOP_PASSIVE_COLLECTION = exports.RESPONSE_START_PASSIVE_COLLECTION = exports.RESPONSE_REMOVE_PASSIVE_COLLECTION = exports.RESPONSE_LIST_PASSIVE_COLLECTIONS = exports.RESPONSE_FIND_PASSIVE_COLLECTION = exports.RESPONSE_DETACH_PASSIVE = exports.RESPONSE_CREATE_PASSIVE_COLLECTION = exports.RESPONSE_ATTACH_PASSIVE = exports.REQUEST_UPDATE_PASSIVE_COLLECTION_HEADER = exports.REQUEST_UPDATE_PASSIVE_COLLECTION = exports.REQUEST_STOP_PASSIVE_COLLECTION = exports.REQUEST_START_PASSIVE_COLLECTION = exports.REQUEST_REMOVE_PASSIVE_COLLECTION = exports.REQUEST_LIST_PASSIVE_COLLECTIONS = exports.REQUEST_FIND_PASSIVE_COLLECTION = exports.REQUEST_CREATE_PASSIVE_COLLECTION = exports.REQUEST_ATTACH_PASSIVE = exports.REQUEEST_DETACH_PASSIVE = void 0;
var _messageModel = require("../../messageModel");
var PASSIVE_COLLECTION = 3027;
var REQUEST_CREATE_PASSIVE_COLLECTION = exports.REQUEST_CREATE_PASSIVE_COLLECTION = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 0);
var REQUEST_UPDATE_PASSIVE_COLLECTION_HEADER = exports.REQUEST_UPDATE_PASSIVE_COLLECTION_HEADER = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 1);
var REQUEST_REMOVE_PASSIVE_COLLECTION = exports.REQUEST_REMOVE_PASSIVE_COLLECTION = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 2);
var REQUEST_ATTACH_PASSIVE = exports.REQUEST_ATTACH_PASSIVE = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 3);
var REQUEEST_DETACH_PASSIVE = exports.REQUEEST_DETACH_PASSIVE = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 4);
var REQUEST_LIST_PASSIVE_COLLECTIONS = exports.REQUEST_LIST_PASSIVE_COLLECTIONS = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 5);
var REQUEST_FIND_PASSIVE_COLLECTION = exports.REQUEST_FIND_PASSIVE_COLLECTION = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 6);
var REQUEST_UPDATE_PASSIVE_COLLECTION = exports.REQUEST_UPDATE_PASSIVE_COLLECTION = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 7);
var REQUEST_START_PASSIVE_COLLECTION = exports.REQUEST_START_PASSIVE_COLLECTION = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 8);
var REQUEST_STOP_PASSIVE_COLLECTION = exports.REQUEST_STOP_PASSIVE_COLLECTION = new _messageModel.RequestMessageDefinition(PASSIVE_COLLECTION, 9);
var RESPONSE_CREATE_PASSIVE_COLLECTION = exports.RESPONSE_CREATE_PASSIVE_COLLECTION = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 0);
var RESPONSE_UPDATE_PASSIVE_COLLECTION_HEADER = exports.RESPONSE_UPDATE_PASSIVE_COLLECTION_HEADER = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 1);
var RESPONSE_REMOVE_PASSIVE_COLLECTION = exports.RESPONSE_REMOVE_PASSIVE_COLLECTION = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 2);
var RESPONSE_ATTACH_PASSIVE = exports.RESPONSE_ATTACH_PASSIVE = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 3);
var RESPONSE_DETACH_PASSIVE = exports.RESPONSE_DETACH_PASSIVE = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 4);
var RESPONSE_LIST_PASSIVE_COLLECTIONS = exports.RESPONSE_LIST_PASSIVE_COLLECTIONS = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 5);
var RESPONSE_FIND_PASSIVE_COLLECTION = exports.RESPONSE_FIND_PASSIVE_COLLECTION = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 6);
var RESPONSE_UPDATE_PASSIVE_COLLECTION = exports.RESPONSE_UPDATE_PASSIVE_COLLECTION = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 7);
var RESPONSE_START_PASSIVE_COLLECTION = exports.RESPONSE_START_PASSIVE_COLLECTION = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 8);
var RESPONSE_STOP_PASSIVE_COLLECTION = exports.RESPONSE_STOP_PASSIVE_COLLECTION = new _messageModel.RespondMessageDefinition(PASSIVE_COLLECTION, 9);