import { UserfulSearchBar } from "userful-chronos-common-ui/dist";
import EmptyPlaceholder from "../../common/components/placeholders/EmptyPlaceholder";
import { KeycloakGroup, KeycloakUser, UserfulGroup, UserfulUser } from "../../model/CarbonExternalModels";
import {
    useCarbonDispatch,
    useCarbonSelector,
    selectFiltersVisibility,
    selectSearchInput,
    selectUserFilters,
} from "../../store/carbonStore";
import { filterUsers } from "../../store/filters/filtersManager";
import { FiltersActions } from "../../store/filters/filtersSlice";
import { ITable } from "../../common/components/UserfulTable/userfulTableInterface";
import { convertKeycloakUserToTable } from "../../common/components/UserfulTable/UserfulTableConverter";
import UserfulTable from "../../common/components/UserfulTable/UserfulTable";
import UserForm from "./UserForm";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

interface IProps {
    users: { [id: string]: UserfulUser };
    groups: { [id: string]: UserfulGroup };
}

export default function UsersTable(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    // Visibility
    const filtersVisibility = useCarbonSelector(selectFiltersVisibility);

    // Filtering
    const constellationFilters = useCarbonSelector(selectUserFilters);
    const searchInput = useCarbonSelector(selectSearchInput);

    // console.warn(props.users);

    // Data
    const allUsers: KeycloakUser[] = [];
    for (const property in props.users) {
        // console.log(props.users[property].user);
        allUsers.push(props.users[property].user);
    }
    const allGroups: KeycloakGroup[] = [];
    for (const property in props.groups) {
        allGroups.push(props.groups[property].group);
    }
    const filteredUsers = filterUsers(constellationFilters, searchInput.users, allUsers);
    const table: ITable = convertKeycloakUserToTable(filteredUsers, allGroups);

    // Functions
    const onSearchBarChange = function (input: string) {
        dispatch(FiltersActions.setSearchInput({ ...searchInput, users: input }));
    };

    if (!permissions.isUserViewer) return null;

    // Renders
    const insertBody = () => {
        if (allUsers.length > 0) {
            if (filteredUsers.length > 0) {
                return null;
            } else {
                return (
                    <div className="center-container">
                        <EmptyPlaceholder
                            img="./images/notFound.png"
                            title={"No Users Found"}
                            description={"Please refine your search/filter criteria and try again."}
                        />
                    </div>
                );
            }
        } else {
            return (
                <div className="center-container">
                    <EmptyPlaceholder
                        img="./images/notFound.png"
                        title={"No Users Available"}
                        description={"There are no available users."}
                    />
                </div>
            );
        }
    };

    return (
        <div className="tableWrapper">
            {allUsers.length > 0 && (
                <>
                    <div className="tableHeader">
                        <div className="horizontalWrapper">
                            <div className="cluster normalGap">
                                {/* <button className="iconButton">
                                    <FontAwesomeIcon icon={faBarsFilter} />
                                </button> */}
                                <UserfulSearchBar forceValue={searchInput.users} onChange={onSearchBarChange} />
                            </div>
                            <div className="cluster normalGap">
                                <UserForm type={"PRIMARY"} permissions={permissions} />
                            </div>
                        </div>
                        {filtersVisibility.users && <div className="horizontalWrapper"></div>}
                    </div>
                </>
            )}
            <div className="tableBodyWrapper">
                <div className="constellationCardsWrapper">
                    <UserfulTable table={table} nonInteractive replace={insertBody()} stretch={filteredUsers.length === 0} />
                </div>
            </div>
        </div>
    );
}
